import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Textarea,
  FormHelperText,
} from "@chakra-ui/react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Chips from "../chips/Chips";

export default function EventForm( props:any ) {

    const { formDefaultValue, setFormDefaultValue, onFormSubmit, isControlledForm, controlledFormId, action } = props

    const schema = yup.object().shape({
        name: yup.string().required('name is required').min(4, "It looks very short"),
        type:yup.string().oneOf(['Competition', 'Webinar', 'Workshop', 'Internship', 'Course', 'Hackathon']),
        // hashtag: yup.array(),
        startDate: yup.date().typeError('Establishment date is either invalid or null').required('Please enter a valid date'),
        endDate: yup.date().typeError('Establishment date is either invalid or null').required('Please enter a valid date'),
        address: yup.string().max(1000),
    });
      
    type SignupFormInputs = {
        name: string;
        type: 'Competition' | 'Webinar' | 'Workshop' | 'Internship' | 'Course' | 'Hackathon';
        hashtag: string[];
        startDate: string;
        endDate: string;
        address: string;
    };

    const { register, handleSubmit, errors, getValues, setValue } = useForm<SignupFormInputs>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: formDefaultValue,
    })

    const onSubmit = (data:any) => {
        onFormSubmit(data)
    }

    useEffect(() => {
        return () => {
            if (action==='create') {
                setFormDefaultValue(getValues())
            } else {
                setFormDefaultValue({})
            }
        }
    }, [getValues, setFormDefaultValue, action])
  
    return (
        <form onSubmit={handleSubmit(onSubmit)} id={controlledFormId}>
            <FormControl mt={5} isInvalid={!!errors?.name} errortext={errors?.name?.message} isRequired>
                <FormLabel htmlFor="name">Event name</FormLabel>
                <Input
                    name="name"
                    type="text"
                    placeholder="What should we call this event?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors?.name && errors?.name?.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.type} errortext={errors?.type?.message} isRequired >
                <FormLabel htmlFor="type">Event Type</FormLabel>
                <Select
                    name="type"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                >
                    <option value='Competition'> Competition </option>
                    <option value='Webinar'> Webinar </option>
                    <option value='Workshop'> Workshop </option>
                    <option value='Internship'> Internship </option>
                    <option value='Course'> Course </option>
                    <option value='Hackathon'> Hackathon </option>
                </Select>
                <FormErrorMessage>
                    {errors.type && errors.type.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.hashtag} >
                <FormLabel htmlFor="hashtag">Event Hashtags</FormLabel>
                <Input
                    name="hashtag"
                    ref={register}
                    display="none"
                />
                <Chips
                    placeholder="Few keywords to pramote it"
                    isDisabled={(action==='preview')?true:undefined}
                    onChipModify={ (arr:any) => {
                        setValue('hashtag', arr)
                    }}
                    defaultValue={(formDefaultValue!.hashtag)?formDefaultValue!.hashtag.split(','):[]}
                />
                <FormErrorMessage>
                    {errors.hashtag && "It's us not you"}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.startDate} errortext={errors?.startDate?.message} isRequired >
                <FormLabel htmlFor="startDate">Start date</FormLabel>
                <Input
                    name="startDate"
                    type="date"
                    defaultValue={new Date().toISOString().split('T')[0]}
                    placeholder="When it all started?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.startDate && errors.startDate.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.endDate} errortext={errors?.endDate?.message} isRequired >
                <FormLabel htmlFor="endDate">End date</FormLabel>
                <Input
                    name="endDate"
                    type="date"
                    defaultValue={new Date().toISOString().split('T')[0]}
                    placeholder="When it all started?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.endDate && errors.endDate.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.address} errortext={errors?.address?.message} >
                <FormLabel htmlFor="address">Event Address</FormLabel>
                <Textarea
                    name="address"
                    placeholder="Where do you hang out?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormHelperText>
                    Write online for online events
                </FormHelperText>
                <FormErrorMessage>
                    {errors.address && errors.address.message}
                </FormErrorMessage>
            </FormControl>

            {
                (!(isControlledForm && controlledFormId)) &&
                <Button 
                    type="submit" 
                    colorScheme="teal"
                    mt={5}
                    float="right"
                > 
                    Submit
                </Button>
            }
        </form>
    )
}

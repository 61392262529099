import { Box, Center, Image, Stack, Text } from '@chakra-ui/react'
import { debounce, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import { handleAlignData, handleDominantBaseline, handleTextAnchor, handleVerticalAlignData } from '../../utils/CommonUtility'

export default function TemplatesPreview(props:any) {
    const { templateData } = props
    const { calculateSidebarWidth, headerHeight, footerHeight } = GlobalDashBoardHandler()
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [, setForceUpdate] = useState({})
    const forceUpdate = React.useCallback(() => setForceUpdate({}), []);

    function getAdjustedConfigObject(actualConfigObject:any) {
        const imgElement = document.getElementById('previewTemplateImage') as HTMLImageElement

        if (!imgElement) {
            return actualConfigObject
        }

        const imgActualHeight = imgElement.naturalHeight
        const imgActualWidth = imgElement.naturalWidth
        const imgRenderedHeight = imgElement.offsetHeight
        const imgRenderedWidth = imgElement.offsetWidth
        const imgRenderedLeftPoint = imgElement.offsetLeft
        const imgRenderedTopPoint = imgElement.offsetTop
        let adjustedConfigObject = {}

        map(actualConfigObject, ( configItem:any ) => {
            const configItemLeft = configItem.left
            const configItemTop = configItem.top
            const configItemWidth = configItem.width
            const configItemHeight = configItem.height

            //@ts-ignore
            adjustedConfigObject[configItem.fieldName] = {
                ...configItem,
                left: (configItemLeft/(imgActualWidth/imgRenderedWidth))+(imgRenderedLeftPoint),
                top: (configItemTop/(imgActualHeight/imgRenderedHeight))+imgRenderedTopPoint,
                width: configItemWidth/(imgActualWidth/imgRenderedWidth),
                height: configItemHeight/(imgActualHeight/imgRenderedHeight),
                ...configItem.fontSize && { fontSize: configItem.fontSize/(imgActualWidth/imgRenderedWidth) }
            }
        })
        return adjustedConfigObject
    }

    const onImageLoad = (e:any) => {
        setIsImageLoaded(true)
    }

    useEffect(() => {
        window.addEventListener("resize", debounce( forceUpdate, 450 ));
        return () => window.removeEventListener("resize", debounce( forceUpdate, 450 ));
    }, [forceUpdate]);

    return (
        <Box w="full" h="full" >
            <Center 
                w="full" 
                h="full" 
                backgroundImage="url('/images/image-loading.svg')"
                backgroundRepeat="no-repeat"
                backgroundAttachment="fixed"
                backgroundPosition="center"
            >
                {
                    (templateData.imgUrl)?
                    <Image 
                        id="previewTemplateImage" 
                        maxH="full" 
                        maxW="full" 
                        src={templateData.imgUrl} 
                        onLoad={onImageLoad}
                    />
                    :
                    <>
                        <Stack>
                            <Text>
                                Looks like your template doesn't have any image yet, Please finish editing first
                            </Text>
                        </Stack>
                    </>
                }
            </Center>
            {
                isImageLoaded && 
                map(getAdjustedConfigObject(templateData.data), (configItem:any) => {
                    return (
                        <Box
                            key={`${configItem.fieldName}`}
                            position="absolute"
                            display={isImageLoaded?"inherit":"none"}
                            w={ configItem.width?`${configItem.width}px`:undefined}
                            h={ configItem.height?`${configItem.height}px`:undefined}
                            top={ configItem.top?configItem.top+'px': [`calc(((((100vh - ${headerHeight + footerHeight}px ) * 0.6 ) - 48px) * 0.5) + 23px)`, `calc((((100vh - ${headerHeight + footerHeight}px ) - 64px) * 0.5) + 23px)`, `calc((((100vh - ${headerHeight + footerHeight}px ) - 96px) * 0.5) + 23px)`]}
                            left={ configItem.left?configItem.left+'px': [`calc((( 100vw * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`, `calc((((( 100vw - ${calculateSidebarWidth()}px ) * 0.6 ) * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`, `calc((((( 100vw - ${calculateSidebarWidth()}px ) * 0.7 ) * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`]}
                        >
                            {
                                (configItem.type === 'qr')?
                                <Image 
                                    minWidth="100%"
                                    src="/images/qr-code-sample.png" 
                                />:
                                <svg 
                                    height="100%" 
                                    width="100%" 
                                >
                                    <text x={handleAlignData( configItem.alignment )} y={handleVerticalAlignData( configItem.verticalAlignment )} dominantBaseline={handleDominantBaseline( configItem.verticalAlignment )} textAnchor={handleTextAnchor( configItem.alignment )} fontFamily={ configItem.fontName } fontSize={ configItem.fontSize } fill={ configItem.fontColor || "#aaaaaa" }>{configItem.displayName}</text>
                                </svg>
                            }
                        </Box>
                    )
                })
            }
        </Box>
    )
}

import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, SkeletonCircle, SkeletonText, Spacer, Stack, Text, useDisclosure, useToast, HStack } from '@chakra-ui/react';
import { find, remove } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowRight, FaEdit, FaEye, FaPlus, FaPlusSquare, FaTimes, FaTrashAlt } from 'react-icons/fa';
import OrganisationForm from '../../components/organisationForm/OrganisationForm';
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable';
import { useAuth } from '../../contexts/AuthContext';
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext';
import { convertFirebaseDateObjectTointernationalDateString, getNewUniqueId } from '../../utils/CommonUtility';
import firebase from 'firebase/app'

export default function OrganisationsPage() {
    const FORM_ID = 'myControlledForm'
    const { db } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()
    const createNewBtnRef = useRef<HTMLButtonElement | null>(null)
    const cancelRef = React.useRef<HTMLButtonElement | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: dialougeIsOpen, onOpen: dialougeOnOpen, onClose:dialougeOnClose } = useDisclosure({id:"delete"})
    const [isLoading, setIsLoading] = useState(false)
    const [tempFormValueHolder, setTempFormValueHolder] = useState({})
    const [activeAction, setActiveAction] = useState({
        id: '',
        action: ''
    })
    const [pageData, setPageData] = useState({
        data: [] as object[],
        loading: true,
        error: {},
        errorStatus: false,
    })
    const toast = useToast()

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    const handleDeleteButtonClick = (id:string, action:string ='delete') => {
        setActiveAction({
            ...activeAction,
            id,
            ...action && {action: action}
        })
        dialougeOnOpen()
    }

    const handleConfirmDeleteButtonClick = async (id:string) => {
        setIsLoading(true)
        try {
            let docRef = db.collection("organisations").doc(id)
            await docRef.set({
                active: false
            }, { merge: true })
            remove(pageData.data, {organisationId: id}) //no Idea why its working, Refector the code using setState later
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to delete template', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
        dialougeOnClose()
        setIsLoading(false)
    }

    const handlePreviewButtonClick = (id:string) => {
        setActiveAction({
            ...activeAction,
            id: id,
            action: "preview"
        })
        const activeOrganisationData = find(pageData.data, {organisationId: id})
        //@ts-ignore
        if (activeOrganisationData && activeOrganisationData.data) {
            setTempFormValueHolder({
                //@ts-ignore
                ...activeOrganisationData.data,
                //@ts-ignore
                establishmentDate: convertFirebaseDateObjectTointernationalDateString(activeOrganisationData.data.establishmentDate),
            })
        }
        onOpen()
    }

    const handleEditButtonClick = (id :string) => {
        setActiveAction({
            ...activeAction,
            id: id,
            action: "edit"
        })
        const activeOrganisationData = find(pageData.data, {organisationId: id})
        //@ts-ignore
        if (activeOrganisationData && activeOrganisationData.data) {
            setTempFormValueHolder({
                //@ts-ignore
                ...activeOrganisationData.data,
                //@ts-ignore
                establishmentDate: convertFirebaseDateObjectTointernationalDateString(activeOrganisationData.data.establishmentDate),
            })
        }
        onOpen()
    }

    const createDataForTable = (data:object[]) => {
        let returnObject = {
            keys: [
                "organisationName",
                // "organisationType",
                "number",
                "email",
                "website",
                // "createdAt",
                "updatedAt",
                "controls"
            ],
            header: {
                organisationName: "Organisation Name",
                organisationType: "Organisation Type",
                number: "Mobile",
                email: "Email Id",
                website: "Website",
                createdAt: "Created At",
                updatedAt: "Updated At",
                controls: "Controls"
            },
            body: [] as object[]
        }
        
        data.map( (item:any) => {
            returnObject.body.push({
                keyId: item.templateId,
                organisationName: item.organisationName,
                organisationType: item.data.type,
                number: item.data.contactNumber,
                email: item.data.emailId,
                website: item.data.website,
                createdAt: <Stack>
                        <Text>
                            {item.createdAt.toDate().toDateString()}
                        </Text>
                        <Text>
                            At {item.createdAt.toDate().toLocaleTimeString()}
                        </Text>
                    </Stack>,
                updatedAt: <Stack>
                        <Text>
                            {item.updatedAt.toDate().toDateString()}
                        </Text>
                        <Text>
                            At {item.updatedAt.toDate().toLocaleTimeString()}
                        </Text>
                    </Stack>,
                controls: <HStack>
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEye />}
                                    aria-label="Show Preview"
                                    onClick={ () => {
                                            handlePreviewButtonClick(item.organisationId)
                                        }
                                    }
                                />
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEdit />}
                                    aria-label="Edit template"
                                    onClick={ () => {
                                            handleEditButtonClick(item.organisationId)
                                        }
                                    }
                                />
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="red"
                                    icon={<FaTimes />}
                                    aria-label="Delete template"
                                    onClick={ () => {
                                            handleDeleteButtonClick(item.organisationId)
                                        }
                                    }
                                />
                    </HStack>
            })
            return true
        })

        return returnObject
    }

    const handleFormSubmit = async (val:any) => {
        setIsLoading(true)
        let docRef = db.collection("organisations")
        const newOrganisationId = getNewUniqueId()
        docRef = docRef.doc(newOrganisationId)
        const firebaseObject = {
            userId: currentUser.uid,
            organisationId: newOrganisationId,
            organisationName: val.name,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            data: val,
            active: true
        }
        try {
            await docRef.set(firebaseObject)
            await fetchFirebaseData()
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to fetch organisations', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
        
        // Logic to handle local data change without firebase query, Will complete later

        // let existingData = pageData.data
        // //@ts-ignore
        // firebaseObject.createdAt = new Date()
        // //@ts-ignore
        // firebaseObject.updatedAt = new Date()
        // existingData.unshift(firebaseObject)
        // setPageData({
        //     ...pageData,
        //     data: existingData,
        // })
        setIsLoading(false)
        onClose()
    }

    const handleFormEdit = async (val:any) => {
        setIsLoading(true)
        let docRef = db.collection("organisations").doc(activeAction.id)
        const firebaseObject = {
            organisationName: val.name,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            data: val,
        }
        try {
            await docRef.set(firebaseObject, { merge: true })
            await fetchFirebaseData()
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to fetch organisations', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
        
        // Logic to handle local data change without firebase query, Will complete later

        // let existingData = pageData.data
        // //@ts-ignore
        // firebaseObject.createdAt = new Date()
        // //@ts-ignore
        // firebaseObject.updatedAt = new Date()
        // existingData.unshift(firebaseObject)
        // setPageData({
        //     ...pageData,
        //     data: existingData,
        // })
        setIsLoading(false)
        onClose()
    }

    const fetchFirebaseData = async () => {
        try {
            let docRef = db.collection("organisations")
            let queryData = await docRef
                                .where("userId", "==", currentUser.uid)
                                .where("active", "==", true)
                                .orderBy("updatedAt", "desc")
                                .get()
            let tempDataHolder:object[] = []
            queryData.forEach((doc:any) => {
                tempDataHolder.push(doc.data())
            })
            setPageData({
                data: tempDataHolder,
                loading: false,
                error: {},
                errorStatus: false,
            })
        } catch (error) {
            setPageData({
                data: [],
                loading: false,
                error,
                errorStatus: true,
            })
            displayToastErrors(error.code?error.code:'Unable to fetch organisations', 
                            error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
    }

    useEffect(() => {
        ( async () => {
            await fetchFirebaseData()
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {   
                pageData.loading ?
                <Box
                    p={3}
                    w="100%"
                >
                    <Flex 
                        w="100%"
                    >
                        <Spacer />
                        <SkeletonCircle size="10" />
                    </Flex>
                    <SkeletonText mt="4" noOfLines={10} spacing="4" />

                </Box>
                :
                <>
                    {
                        pageData.errorStatus?
                        <>
                        </>
                        :
                        <>
                            {
                                (pageData.data.length === 0)?
                                <>
                                    <Center
                                        w="100%"
                                        h="100%"
                                        p={3}
                                    >
                                        <Stack>
                                            <Text>
                                                It Looks like you haven't created any organisation yet, Please Create one
                                            </Text>
                                            <Button
                                                colorScheme="teal"
                                                rightIcon={<FaPlusSquare />}
                                                ref={createNewBtnRef}
                                                onClick={ () => {
                                                    setActiveAction({
                                                        ...activeAction,
                                                        action: "create"
                                                    })
                                                    onOpen()
                                                }}
                                            >
                                                Create
                                            </Button>
                                        </Stack>
                                    </Center>
                                </>
                                :
                                <>
                                    <Flex direction="row">
                                        <Spacer />
                                        <IconButton
                                            size="lg"
                                            fontSize="lg"
                                            colorScheme="teal"
                                            onClick={ () => {
                                                setActiveAction({
                                                    ...activeAction,
                                                    action: "create"
                                                })
                                                onOpen()
                                            }}
                                            borderRadius="50%"
                                            mr="3"
                                            my="3"
                                            icon={<FaPlus />}
                                            aria-label="Create new template"
                                        />
                                    </Flex>
                                    <ResponsiveTable data={createDataForTable(pageData.data)} />
                                </>
                            }
                        </>
                    }
                </>
            }
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={ () => {
                    onClose()
                    if (activeAction.action!=='create') {
                        setTempFormValueHolder({})
                    }
                }}
                finalFocusRef={createNewBtnRef}
                size="sm"
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Create new organisation</DrawerHeader>

                        <DrawerBody>
                            <OrganisationForm 
                                formDefaultValue={tempFormValueHolder} 
                                setFormDefaultValue={setTempFormValueHolder} 
                                onFormSubmit={ (val:any) => {
                                    if (activeAction.action==='create') {
                                        handleFormSubmit(val)
                                    }
                                    if (activeAction.action==='edit') {
                                        handleFormEdit(val)
                                    }
                                }}
                                isControlledForm={true}
                                controlledFormId={FORM_ID}
                                action={activeAction.action}
                            />
                        </DrawerBody>

                        <DrawerFooter>
                            <Button variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            {
                                (activeAction.action === 'create') &&
                                <>
                                    <Button 
                                        ml={3}
                                        colorScheme="red" 
                                        rightIcon={<FaTrashAlt />} 
                                        type="reset"
                                        form={FORM_ID}
                                    >
                                        Reset
                                    </Button>
                                    <Button 
                                        ml={3}
                                        colorScheme="teal" 
                                        rightIcon={<FaArrowRight />} 
                                        type="submit"
                                        form={FORM_ID}
                                        isLoading={isLoading}
                                        loadingText="Creating..."
                                    >
                                        Create
                                    </Button>
                                </>
                            }
                            {
                                (activeAction.action === 'edit') &&
                                <>
                                    <Button 
                                        ml={3}
                                        colorScheme="teal" 
                                        rightIcon={<FaEdit />} 
                                        type="submit"
                                        form={FORM_ID}
                                        isLoading={isLoading}
                                        loadingText="Saving..."
                                    >
                                        Save
                                    </Button>
                                </>
                            }
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>

            <AlertDialog
                isOpen={dialougeIsOpen}
                leastDestructiveRef={cancelRef}
                onClose={dialougeOnClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Organisation
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={dialougeOnClose}>
                                Cancel
                            </Button>
                            <Button 
                                colorScheme="red" 
                                isLoading={isLoading}
                                loadingText="Deleting..."
                                onClick={ () => {
                                        handleConfirmDeleteButtonClick(activeAction.id)
                                    }
                                } 
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

import React, { useEffect, useState } from 'react'

export default function Counter(props:any) {
    const stepSize = props.ss || props.stepSize || 1
    const timerSize = props.ts || props.timerSize || 30
    const callBackOnTimerEnd = props.callBackOnTimerEnd
    const setInfiniteLoop = props.setInfiniteLoop || props.il || false
    
    const [counterTimer, setCounterTimer] = useState(timerSize)

    useEffect(() => {
        let myIntervalId:any;
        if (counterTimer > 0) {
            myIntervalId = setTimeout( () => {
                setCounterTimer( counterTimer - stepSize)
            }, stepSize * 1000);
        } else {
            clearInterval(myIntervalId);
            (async function () {
                await callBackOnTimerEnd();
                if( setInfiniteLoop ) {
                    myIntervalId = setTimeout( () => {
                        setCounterTimer( timerSize )
                    }, stepSize * 1000);
                }
            })();
        }
        return () => {
            return clearInterval(myIntervalId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counterTimer, callBackOnTimerEnd, stepSize])

    return (
        <>
            { counterTimer }
        </>
    )
}

import { Center, Text } from '@chakra-ui/layout'
import React from 'react'

export default function DashBoardHomeSubPage() {
    return (
        <Center>
            <Text>
                Page under construction
            </Text>
        </Center>
    )
}

import React, { useRef } from 'react'
import { FaArrowLeft, FaBars, FaTimes } from 'react-icons/fa'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, useDisclosure } from '@chakra-ui/react'
import Logo from '../logo/Logo'
import Sidebar from '../sidebar/Sidebar'
import "./SidebarController.css"

export default function SidebarController() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const menuButtonRef = useRef<HTMLButtonElement | null>(null)

    const { deviceType, sidebarState, toggleSidebarState } = GlobalDashBoardHandler()

    const PortraitSidebar = ( params:any ) => {
        return (
            <>
                <IconButton
                    size="md"
                    fontSize="lg"
                    variant="ghost"
                    color="current"
                    ml={1}
                    onClick={onOpen}
                    icon={<FaBars />}
                    aria-label="open sidebar"
                    ref={menuButtonRef}
                />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    finalFocusRef={menuButtonRef}
                >
                    <DrawerOverlay>
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>
                                <Logo />
                            </DrawerHeader>

                            <DrawerBody>
                                <Sidebar />
                            </DrawerBody>
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
            </>
        )
    }

    const LandscapeSidebar = ( params:any ) => {
        return (
            <>
                
            </>
        )
    }

    const ShowSidebar = ( params:any ) => {
        if (deviceType === 'portrait') {
            return (
                <PortraitSidebar />
            )
        } else {
            return (
                <LandscapeSidebar />
            )
        }
    }

    return (
        <>
            <ShowSidebar />
            <IconButton
                size="md"
                fontSize="lg"
                variant="ghost"
                color="current"
                ml={1}
                onClick={toggleSidebarState}
                display={(deviceType === 'portrait')?"none":"inherit"}
                icon={
                    <>
                        <FaBars size={18} className={(sidebarState === 0)?"menu-icon-first rotate-icon-change-old-icon":"menu-icon-first rotate-icon-change-new-icon"} />
                        <FaTimes size={18} className={(sidebarState === 1)?"menu-icon-second rotate-icon-change-old-icon":"menu-icon-second rotate-icon-change-new-icon"} />
                        <FaArrowLeft size={18} className={(sidebarState === 2)?"menu-icon-third rotate-icon-change-old-icon":"menu-icon-third rotate-icon-change-new-icon"} />
                    </>
                }
                aria-label="toggle sidebar"
                ref={menuButtonRef}
            />
        </>
    )
}

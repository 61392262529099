import { Avatar, Circle, Flex, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FaBriefcase, FaSignOutAlt, FaUserAstronaut } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import Logo from './logo/Logo'
import SidebarController from './sidebarController/SidebarController'

export default function HeaderNavbar() {
    const { isUserLoggedin, currentUser, signOut } = useAuth()
    const history = useHistory()

    const handleSignOut = async function () {
        await signOut()
        history.push("/login")
    }

    return (
        <Flex direction="row" flexGrow={1} alignItems="center" boxShadow="md">
            { isUserLoggedin && <SidebarController /> }
            <Logo />
            <Spacer />
            <ColorModeSwitcher mr={2} />
            {
                isUserLoggedin && 
                <>
                    <Menu>
                        <MenuButton>
                            <Avatar name={currentUser?.displayName} src={currentUser?.photoURL} size="sm" justifySelf="flex-end" mr={3} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => history.push('/dashboard/profile')}> <Circle mr={3} mt={1}><FaUserAstronaut /></Circle> <span>Profile</span></MenuItem>
                            <MenuItem> <Circle mr={3} mt={1}><FaBriefcase /></Circle> <span>Account</span></MenuItem>
                            <MenuDivider />
                            <MenuItem
                                onClick={handleSignOut}
                            >
                                <Circle mr={3} mt={1}>
                                    <FaSignOutAlt />
                                </Circle>
                                <span>Sign Out</span>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </>
            }
        </Flex>
    )
}

import React from 'react'
import { Image, useColorModeValue } from "@chakra-ui/react"
import gmcLogo from "../../assets/images/GMC_logo.png"
import gmcLogoLight from "../../assets/images/GMC_logo_light.png"
import { Link } from 'react-router-dom'

export default function Logo() {
    const gmcLogoToUse = useColorModeValue(gmcLogo, gmcLogoLight)
    return (
        <Link to="/">
            <Image src={gmcLogoToUse} h={10} mx={-1}></Image>
        </Link>
    )
}

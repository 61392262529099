import React, { useContext, useState, useEffect, createContext } from 'react'
import firebase from 'firebase/app'
import {auth} from '../firebase'
import Loading from '../pages/loading/Loading'

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [sendOTPResult, setSendOTPResult] = useState(false)
    const [isUserLoggedin, setIsUserLoggedin] = useState(false)

    function signupByEmail(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function loginByEmail(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function signOut() {
        return auth.signOut()
    }

    function loginByGoogle() {
        var provider = new firebase.auth.GoogleAuthProvider()
        return auth.signInWithRedirect(provider)
    }

    function getRedirectLoginResult(){
        return auth.getRedirectResult()
    }

    function loadAdvanceCaptcha() {
        if (!window.recaptchaVerifier) {
            auth.useDeviceLanguage()
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-otp-button', {
                size: 'invisible'
            })
        }
    }

    async function linkPhoneWithUserSendOTP(phoneNumber) {
        const appVerifier = window.recaptchaVerifier
        try {
            const sendOTPResult = await auth.currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
            setSendOTPResult(sendOTPResult)
            return {
                status: "success",
                statusCode: 200,
                data: sendOTPResult
            }
        } catch (error) {
            return {
                status: "fail",
                statusCode: 400,
                data: error
            }
        }
    }

    async function linkPhoneWithUserVerifyOTP(codeOTP) {
        if (sendOTPResult) {
            try {
                // await sendOTPResult.confirm(codeOTP)
                const credential = await firebase.auth.PhoneAuthProvider.credential(sendOTPResult.verificationId, codeOTP)
                await auth.currentUser.linkWithCredential(credential)
                return {
                    status: "success",
                    statusCode: 200,
                    data: credential
                }
            } catch (error) {
                return {
                    status: "fail",
                    statusCode: 400,
                    data: error
                }
            }
        }
    }

    async function sendEmailVerificationMail() {
        try {
            const result = await auth.currentUser.sendEmailVerification({
                // url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
                url: "http://localhost:3000",
            })
            // const result = (ms) => new Promise(res => setTimeout(res, ms))
            return {
                status: "success",
                statusCode: 200,
                // data: await result(5000)
                data: result
            }
        } catch (error) {
            return {
                status: "fail",
                statusCode: 400,
                data: error
            }
        }
    }

    async function refreshUser() {
        return await auth.currentUser.reload()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            if(user){
                setIsUserLoggedin(true)
            } else {
                setIsUserLoggedin(false)
            }
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        isUserLoggedin,
        loginByEmail,
        loginByGoogle,
        signupByEmail,
        getRedirectLoginResult,
        signOut,
        sendEmailVerificationMail,
        refreshUser,
        loadAdvanceCaptcha,
        linkPhoneWithUserSendOTP,
        linkPhoneWithUserVerifyOTP
    }
    return (
        <AuthContext.Provider value={value}>
            {loading?<Loading />: children}
        </AuthContext.Provider>
    )
}

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function HandleLoginRoute({ component: Component, ...rest }: any) {
    const { currentUser } = useAuth()
    return (
        <Route
            {...rest}
            render={props => {
                return currentUser ? <Redirect to="/dashboard" /> : <Component {...props} />
            }}
        >

        </Route>
    )
}

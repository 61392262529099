import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Textarea,
  Divider
} from "@chakra-ui/react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInputWithDarkMode from "../phoneInputWithDarkMode/PhoneInputWithDarkMode";

export default function OrganisationForm( props:any ) {

    const { formDefaultValue, setFormDefaultValue, onFormSubmit, isControlledForm, controlledFormId, action } = props

    const schema = yup.object().shape({
        name: yup.string().required('name is required').min(4, "It looks very short"),
        type:yup.string().oneOf(['Technical', 'Social']),
        about: yup.string().max(1000),
        establishmentDate: yup.date().typeError('Establishment date is either invalid or null').required('Please enter a valid date'),
        size: yup.number().typeError('Organisation size must be a number').min(1).max(10000),
        address: yup.string().max(1000),
        contactNumber: yup.string().required('We need your mobile number to contact'),
        emailId: yup.string().email().required("Email Id is required"),
        website: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
                'Enter correct url!'
            ),
        linkedInId: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
                'Enter correct url!'
            ),
        twitterId: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
                'Enter correct url!'
            ),
        facebookId: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
                'Enter correct url!'
            ),
        instagramId: yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
                'Enter correct url!'
            )
    });
      
    type SignupFormInputs = {
        name: string;
        type: 'Technical' | 'Social';
        about: string;
        establishmentDate: string;
        size: number;
        address: string;
        contactNumber: string;
        emailId: string;
        website: string;
        linkedInId: string;
        twitterId: string;
        facebookId: string;
        instagramId: string;
    };

    const { register, handleSubmit, errors, getValues } = useForm<SignupFormInputs>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: formDefaultValue,
    });
    const onSubmit = (data:any) => {
        onFormSubmit(data)
    }

    useEffect(() => {
        return () => {
            if (action==='create') {
                setFormDefaultValue(getValues())
            } else {
                setFormDefaultValue({})
            }
        }
    }, [getValues, setFormDefaultValue, action])
  
    return (
        <form onSubmit={handleSubmit(onSubmit)} id={controlledFormId}>
            <FormControl mt={5} isInvalid={!!errors?.name} errortext={errors?.name?.message} isRequired>
                <FormLabel htmlFor="name">Organisation name</FormLabel>
                <Input
                    name="name"
                    placeholder="What should we call it?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors?.name && errors?.name?.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.type} errortext={errors?.type?.message} isRequired >
                <FormLabel htmlFor="type">Organisation Type</FormLabel>
                <Select
                    name="type"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                >
                    <option value="Technical">Technical</option>
                    <option value="Social">Social</option>
                </Select>
                <FormErrorMessage>
                    {errors.type && errors.type.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.about} errortext={errors?.about?.message} >
                <FormLabel htmlFor="about">Organization Details</FormLabel>
                <Textarea
                    name="about"
                    placeholder="What this is all about?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.about && errors.about.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.establishmentDate} errortext={errors?.establishmentDate?.message} isRequired >
                <FormLabel htmlFor="establishmentDate">Establishment date</FormLabel>
                <Input
                    name="establishmentDate"
                    type="date"
                    defaultValue={new Date().toISOString().split('T')[0]}
                    placeholder="When it all started?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.establishmentDate && errors.establishmentDate.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.size} errortext={errors?.size?.message} isRequired>
                <FormLabel htmlFor="size">Organization Size</FormLabel>
                <Input
                    name="size"
                    type="number"
                    defaultValue="1"
                    placeholder="What is your pack size?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.size && errors.size.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.address} errortext={errors?.address?.message} isRequired >
                <FormLabel htmlFor="address">Contact Address</FormLabel>
                <Textarea
                    name="address"
                    placeholder="Where do you hang out?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.address && errors.address.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.contactNumber} errortext={errors?.contactNumber?.message} isRequired >
                <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
                <Input
                    name="contactNumber"
                    type="tel"
                    ref={register}
                    id="hiddenHackForMobileInput"
                    w="100%"
                    position="absolute"
                    color="rgba(0,0,0,0)"
                />
                <PhoneInputWithDarkMode 
                    country={'in'}
                    placeholder="Where should we contect you?"
                    value={formDefaultValue.contactNumber}
                    disabled={(action==='preview')?true:undefined}
                    onChange={ (phone:any) => {
                        //@ts-ignore
                        document.getElementById("hiddenHackForMobileInput").value = phone
                    }}
                    onBlur={ () => {
                        document.getElementById("hiddenHackForMobileInput")?.focus()
                        document.getElementById("hiddenHackForMobileInput")?.blur()
                    }}
                />
                <FormErrorMessage>
                    {errors.contactNumber && errors.contactNumber.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.emailId} errortext={errors?.emailId?.message} isRequired >
                <FormLabel htmlFor="emailId">Email Id</FormLabel>
                <Input
                    name="emailId"
                    type="email"
                    placeholder="what is your digital contect?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.emailId && errors.emailId.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.website} errortext={errors?.website?.message} >
                <FormLabel htmlFor="website">Website</FormLabel>
                <Input
                    name="website"
                    type="text"
                    placeholder="what is your website?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.website && errors.website.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.linkedInId} errortext={errors?.linkedInId?.message} >
                <FormLabel htmlFor="linkedInId">LinkedIn Id</FormLabel>
                <Input
                    name="linkedInId"
                    type="text"
                    placeholder="what is your linkedIn id?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.linkedInId && errors.linkedInId.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.twitterId} errortext={errors?.twitterId?.message} >
                <FormLabel htmlFor="twitterId">Twitter Id</FormLabel>
                <Input
                    name="twitterId"
                    type="text"
                    placeholder="what is your twitter id?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.twitterId && errors.twitterId.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={!!errors?.facebookId} errortext={errors?.facebookId?.message} >
                <FormLabel htmlFor="facebookId">Facebook Id</FormLabel>
                <Input
                    name="facebookId"
                    type="text"
                    placeholder="what is your facebook id?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.facebookId && errors.facebookId.message}
                </FormErrorMessage>
            </FormControl>
            
            <FormControl mt={5} isInvalid={!!errors?.instagramId} errortext={errors?.instagramId?.message} >
                <FormLabel htmlFor="instagramId">InstagramId</FormLabel>
                <Input
                    name="instagramId"
                    type="text"
                    placeholder="what is your instagram id?"
                    ref={register}
                    isDisabled={(action==='preview')?true:undefined}
                />
                <FormErrorMessage>
                    {errors.instagramId && errors.instagramId.message}
                </FormErrorMessage>
            </FormControl>

            {
                (!(isControlledForm && controlledFormId)) &&
                <Button 
                    type="submit" 
                    colorScheme="teal"
                    mt={5}
                    float="right"
                > 
                    Submit
                </Button>
            }
            <Divider mt={5} />
        </form>
    )
}
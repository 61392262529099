import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import OuterLayout from "./layout/outerLayout/OuterLayout"
import Signup from "./pages/signup/Signup"
import "./App.css"
import Login from "./pages/login/Login"
import { AuthProvider } from "./contexts/AuthContext"
import PrivateRoute from "./routes/PrivateRoute"
import { DashBoardProvider } from "./contexts/DashBoardContext"
import HandleLoginRoute from "./routes/HandleLoginRoute"

export const App = () => (
  <Router>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <DashBoardProvider>
          <Switch>
            <Route path="/signup" component={Signup} />
            {/* <Route path="/login" component={Login} /> */}
            <HandleLoginRoute path="/login" component={Login} />
            <PrivateRoute path="/dashboard" component={OuterLayout} />
            <Route path="/" component={Login} />
          </Switch>
        </DashBoardProvider>
      </AuthProvider>
    </ChakraProvider>
  </Router>
)

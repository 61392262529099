import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'
import 'firebase/storage'

// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// })

const app = firebase.initializeApp({
    apiKey: "AIzaSyB5dYtq2PzmxOGym_ZFJsPwWTUBaD8rXbA",
    authDomain: "gmc-testing.firebaseapp.com",
    databaseURL: "https://gmc-testing.firebaseio.com",
    projectId: "gmc-testing",
    storageBucket: "gmc-testing.appspot.com",
    messagingSenderId: "576038022824",
    appId: "1:576038022824:web:40605c04458068d9135c9d",
    measurementId: "G-0CZ1E10TL8"
  })

export const auth = app.auth();
export default app;
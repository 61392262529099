import { Box, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export default function WrapperBox( props:any ) {
    const { children, propsToText, PropsToBox, placeholderText, ...propsToPass } = props
    const placeholderBackgroundColour = useColorModeValue("white", "#1a202c")

    return (
        <Box border="1px solid" p={2} mt={4} {...PropsToBox} {...propsToPass}>
            <Text mt={-5} px={1} bg={placeholderBackgroundColour} maxW="fit-content" w="max-content" fontSize="xs" mb={1} {...propsToText} >
                {placeholderText}
            </Text>
            {children}
        </Box>
    )
}

import { Box, IconButton, Input, InputGroup, InputLeftElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Switch, Text, useColorModeValue, WrapItem } from '@chakra-ui/react'
import { find } from 'lodash'
import React from 'react'
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaEyeDropper } from 'react-icons/fa'
import { MdVerticalAlignBottom, MdVerticalAlignCenter, MdVerticalAlignTop } from 'react-icons/md' 
import WrapperBox from '../wrapperBox/WrapperBox'

export default function DrawConfigBoxItem( props:any ) {

    const { data, onUpdate, fontsObject } = props
    const placeholderBackgroundColour = useColorModeValue("white", "#1a202c")

    type fontObjType = typeof fontsObject[0]

    const updateHandler = (updatedData:any) => onUpdate({ ...data, ...updatedData})

    const fetchStyle = function( url:string ) {
        console.log(url);
        
        return new Promise((resolve, reject) => {
          let link = document.createElement('link');
          link.rel = 'stylesheet';
          //@ts-ignore
          link.onload = () => { resolve() };
          link.href = url;
      
          let headScript = document.querySelector('script');
          headScript!.parentNode!.insertBefore(link, headScript);
        });
    };

    const updateFontHandler = async ( selectedFontObj:any ) => {
        if (selectedFontObj) {
            console.log('222222222222222', selectedFontObj, '3333333333333333')
            const selectedFontObject = find(fontsObject, ['name', selectedFontObj.fontName])
            console.log('loading begins')
            await fetchStyle(selectedFontObject.hostPath)
            console.log('loading done')
            updateHandler(selectedFontObj)
        }
    }

    return (
        <>
            {
                data.alignment &&
                <WrapItem>
                    <Box border="1px solid" p={2} mt={4}>
                        <Text mt={-5} px={1} bg={placeholderBackgroundColour} w="max-content" fontSize="xs" mb={1} >
                            Alignment
                        </Text>
                        <IconButton 
                            borderRightRadius={0} 
                            borderRight="1px solid" 
                            colorScheme="teal" 
                            variant={(data.alignment === 'left')?'solid':'outline'} 
                            onClick={ () => 
                                {
                                    updateHandler({
                                        alignment: "left",
                                    })
                                }
                            }
                            icon={<FaAlignLeft />}
                            aria-label="left align"
                        />
                        <IconButton 
                            borderRightRadius={0} 
                            borderLeftRadius={0} 
                            colorScheme="teal" 
                            variant={(data.alignment === 'center')?'solid':'outline'} 
                            onClick={ () => 
                                {
                                    updateHandler({
                                        alignment: "center",
                                    })
                                }
                            }
                            icon={<FaAlignCenter />}
                            aria-label="Center align"
                        />
                        <IconButton 
                            borderLeftRadius={0} 
                            borderLeft="1px solid" 
                            colorScheme="teal" 
                            variant={(data.alignment === 'right')?'solid':'outline'} 
                            onClick={ () => 
                                {
                                    updateHandler({
                                        alignment: "right",
                                    })
                                }
                            }
                            icon={<FaAlignRight />}
                            aria-label="Right align"
                        />
                    </Box>
                </WrapItem>
            }
            {
                data.fontColor &&
                <WrapItem>
                    <Box border="1px solid" p={2} mt={4}>
                    <Text mt={-5} px={1} bg={placeholderBackgroundColour} w="max-content" fontSize="xs" mb={1} >
                        Font Colour
                    </Text>
                    <InputGroup >
                        <InputLeftElement
                            pointerEvents="none"
                            children={<FaEyeDropper size={12} />}
                        />
                        <Input 
                            w="100%"
                            type="color" 
                            value={data.fontColor}  
                            onChange={(e) => {
                                    updateHandler({
                                        fontColor: e.target.value
                                    })
                                }
                            }
                        />
                    </InputGroup>
                </Box>
                </WrapItem>
            }
            {
                data.fontSize &&
                <WrapItem>
                    <Box border="1px solid" p={2} mt={4}>
                        <Text mt={-5} px={1} bg={placeholderBackgroundColour} w="max-content" fontSize="xs" mb={1} >
                            Font Size
                        </Text>
                        <NumberInput 
                            step={2} 
                            defaultValue={40} 
                            value={Math.round(data.fontSize)} 
                            min={4} 
                            max={400} 
                            allowMouseWheel={true} 
                            w="5rem"
                            onChange={(valueString) => {
                                    updateHandler({
                                        fontSize: valueString || 4
                                    })
                                }
                            } 
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>
                </WrapItem>
            }
            {
                data.verticalAlignment &&
                <WrapItem>
                    <Box border="1px solid" p={2} mt={4}>
                    <Text mt={-5} px={1} bg={placeholderBackgroundColour} w="max-content" fontSize="xs" mb={1} >
                        Vertical Align
                    </Text>
                    <IconButton 
                        borderRightRadius={0} 
                        borderRight="1px solid" 
                        colorScheme="teal" 
                        variant={(data.verticalAlignment === 'top')?'solid':'outline'} 
                        onClick={ () => 
                            {
                                updateHandler({
                                    verticalAlignment: "top",
                                })
                            }
                        }
                        icon={<MdVerticalAlignTop />}
                        aria-label="top align"
                    />
                    <IconButton 
                        borderRightRadius={0} 
                        borderLeftRadius={0} 
                        colorScheme="teal" 
                        variant={(data.verticalAlignment === 'middle')?'solid':'outline'} 
                        onClick={ () => 
                            {
                                updateHandler({
                                    verticalAlignment: "middle",
                                })
                            }
                        }
                        icon={<MdVerticalAlignCenter />}
                        aria-label="Middle align"
                    />
                    <IconButton 
                        borderLeftRadius={0} 
                        borderLeft="1px solid" 
                        colorScheme="teal" 
                        variant={(data.verticalAlignment === 'bottom')?'solid':'outline'} 
                        onClick={ () => 
                            {
                                updateHandler({
                                    verticalAlignment: "bottom",
                                })
                            }
                        }
                        icon={<MdVerticalAlignBottom />}
                        aria-label="Bottom align"
                    />
                </Box>
                </WrapItem>
            }
            {
                data.verticalAlignment &&
                <WrapItem>
                    <Box border="1px solid" p={2} mt={4}>
                        <Text mt={-5} px={1} bg={placeholderBackgroundColour} w="max-content" fontSize="xs" mb={1} >
                            Font
                        </Text>
                        <Select 
                            placeholder="Select Font" 
                            value={data.fontName}
                            onChange={(e) => {
                                updateFontHandler({
                                    fontName: e.target.value
                                })
                            }} 
                        >
                            {
                                fontsObject.map(( fontObj:fontObjType )=>{
                                    return (
                                        <option value={fontObj.name} key={fontObj.id}>{fontObj.displayName}</option>
                                    )
                                })
                            }
                        </Select>
                    </Box>
                </WrapItem>
            }
            {
                // data.required && 
                <WrapItem>
                    <WrapperBox
                        placeholderText="Make it required?"
                    >
                        <Switch 
                            id="required" 
                            colorScheme="teal" 
                            aria-label="Make this field required?" 
                            isChecked={data.required} 
                            onChange={(e) => {
                                updateHandler({
                                    required: !data.required
                                })
                            }}
                        />
                    </WrapperBox>
                </WrapItem>
            }
        </>
    )
}

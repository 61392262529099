import { useBreakpointValue } from '@chakra-ui/react'
import React, { createContext, useContext, useState } from 'react'
import { useAuth } from './AuthContext'
import app from '../firebase'

type defaultContext = {
    sidebarState: number;
    toggleSidebarState: () => void;
    showEmailVerification: boolean;
    setShowEmailVerification: (state:boolean) => void;
    deviceType: string | undefined;
    db: any;
    storage: any;
    headerHeight: number;
    footerHeight: number;
    calculateSidebarWidth: () => number | string;
}

const DashBoardContext = createContext({} as defaultContext)

export function GlobalDashBoardHandler() {
    return useContext(DashBoardContext)
}

export function DashBoardProvider({ children }: any) {
    const { currentUser } = useAuth()
    const [showEmailVerification, setShowEmailVerification] = useState(!currentUser?.emailVerified)
    const headerHeight = 48
    const footerHeight = 48

    let menuVerient:number | undefined = 3
    menuVerient = useBreakpointValue({ base: 1, md: 2 })

    let deviceType:string | undefined = "landscape"
    deviceType = useBreakpointValue({ base: "portrait", md: "landscape" })

    // const [sidebarState, setSidebarState] = useState(menuVerient || 2)
    const [sidebarState, setSidebarState] = useState(1)
    const db = app.firestore()
    const storage = app.storage()

    function toggleSidebarState() {
        if (sidebarState === 0) {
            setSidebarState(menuVerient!)
        } else {
            setSidebarState(sidebarState - 1)
        }
    }

    const calculateSidebarWidth = () => {
        if (deviceType === 'portrait') {
            return "0"
        }
        switch (sidebarState) {
            case 2:
                return "250"

            case 1:
                return "70"

            case 0:
                return "0"
        
            default:
                return "70"
        }
    }

    const value = {
        sidebarState,
        toggleSidebarState,
        showEmailVerification,
        setShowEmailVerification,
        deviceType,
        db,
        storage,
        headerHeight,
        footerHeight,
        calculateSidebarWidth,
    }

    return (
        <DashBoardContext.Provider value={value}>
            { children }
        </DashBoardContext.Provider>
    )
}

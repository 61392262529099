import { Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, Input, SkeletonCircle, SkeletonText, Spacer, Stack, Text, useDisclosure, useToast, Image, HStack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, AlertDialogCloseButton } from '@chakra-ui/react'
import { find, remove } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowRight, FaPlusSquare, FaPlus, FaEye, FaEdit, FaTimes } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import TemplatesPreview from '../../components/templatesPreview/TemplatesPreview'
import { useAuth } from '../../contexts/AuthContext'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import CertificateBuilder from '../certificateBuilder/CertificateBuilder'
import CertificateBuilderWrapper from '../certificateBuilderWrapper/CertificateBuilderWrapper'

export default function TemplatesPage() {

    const { db } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()
    const params = useParams()
    //@ts-ignore
    const templateId = params.templateId || ''
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: dialougeIsOpen, onOpen: dialougeOnOpen, onClose:dialougeOnClose } = useDisclosure({id:"delete"})
    const { isOpen: fullDrawerIsOpen, onOpen: fullDrawerOnOpen, onClose:fullDrawerOnClose } = useDisclosure({id:"full"})
    const toast = useToast()
    const createNewTemplateBtnRef = useRef<HTMLButtonElement | null>(null)
    const templateNameRef = useRef<HTMLInputElement | null>(null)
    const cancelRef = React.useRef<HTMLButtonElement | null>(null)
    const [isNewCreation, setIsNewCreation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activeTemplateAction, setActiveTemplateAction] = useState({
        templateId: '',
        action: ''
    })
    const [templatesPageData, setTemplatesPageData] = useState({
        data: [] as object[],
        loading: true,
        error: {},
        errorStatus: false,
    })

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    useEffect(() => {
        if (templateId) {
            return
        }
        ( async () => {
            try {
                let templetDocRef = db.collection("templates")
                let templetsData = await templetDocRef
                                    .where("userId", "==", currentUser.uid)
                                    .where("active", "==", true)
                                    .orderBy("updatedAt", "desc")
                                    .get()
                let tempDataHolder:object[] = []
                templetsData.forEach((doc:any) => {
                    tempDataHolder.push(doc.data())
                })
                setTemplatesPageData({
                    data: tempDataHolder,
                    loading: false,
                    error: {},
                    errorStatus: false,
                })
            } catch (error) {
                console.log(error)
                setTemplatesPageData({
                    data: [],
                    loading: false,
                    error,
                    errorStatus: true,
                })
                displayToastErrors(error.code?error.code:'Unable to fetch templates', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNewTemplateCreateButtonClick = (e:any) => {
        setIsNewCreation(true)
    }

    const handleDeleteButtonClick = (templateId:string) => {
        setActiveTemplateAction({
            ...activeTemplateAction,
            templateId
        })
        dialougeOnOpen()
    }

    const handleConfirmDeleteButtonClick = async (templateId:string) => {
        setIsLoading(true)
        try {
            let templetDocRef = db.collection("templates").doc(templateId)
            await templetDocRef.set({
                active: false
            }, { merge: true })
            remove(templatesPageData.data, {templateId: templateId}) //no Idea why its working, Refector the code using setState later
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to delete template', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
        dialougeOnClose()
        setIsLoading(false)
    }

    const getTemplateDataFromTemplateId = (templateId:string) => {
        return find(templatesPageData.data, {templateId: templateId})
    }

    const handlePreviewButtonClick = (templateId:string) => {
        setActiveTemplateAction({
            ...activeTemplateAction,
            templateId: templateId,
            action: "preview"
        })
        fullDrawerOnOpen()
    }

    const handleEditButtonClick = (templateId:string) => {
        setActiveTemplateAction({
            ...activeTemplateAction,
            templateId: templateId,
            action: "edit"
        })
        fullDrawerOnOpen()
    }

    const createDataForTable = (data:object[]) => {
        let returnObject = {
            keys: [
                "templateName",
                "createdAt",
                "updatedAt",
                "templateImg",
                "controls"
            ],
            header: {
                templateName: "Template Name",
                createdAt: "Created At",
                updatedAt: "Updated At",
                templateImg: "Template Image",
                controls: "Controls"
            },
            body: [] as object[]
        }
        
        data.map( (item:any) => {
            returnObject.body.push({
                keyId: item.templateId,
                templateName: <Link to={`/dashboard/templates/${item.templateId}`}>
                                {item.templateName}
                            </Link>,
                createdAt: <Stack>
                        <Text>
                            {item.createdAt.toDate().toDateString()}
                        </Text>
                        <Text>
                            At {item.createdAt.toDate().toLocaleTimeString()}
                        </Text>
                    </Stack>,
                updatedAt: <Stack>
                        <Text>
                            {item.updatedAt.toDate().toDateString()}
                        </Text>
                        <Text>
                            At {item.updatedAt.toDate().toLocaleTimeString()}
                        </Text>
                    </Stack>,
                templateImg: <Image src={item.imgUrl} maxH="60px" minH="60px" />,
                controls: <HStack>
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEye />}
                                    aria-label="Show Preview"
                                    onClick={ () => {
                                            handlePreviewButtonClick(item.templateId)
                                        }
                                    }
                                />
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEdit />}
                                    aria-label="Edit template"
                                    onClick={ () => {
                                            handleEditButtonClick(item.templateId)
                                        }
                                    }
                                />
                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="red"
                                    icon={<FaTimes />}
                                    aria-label="Delete template"
                                    onClick={ () => {
                                            handleDeleteButtonClick(item.templateId)
                                        }
                                    }
                                />
                    </HStack>
            })
            return true
        })

        return returnObject
    }

    return (
        <>
            {
                templateId?
                <>
                    <CertificateBuilderWrapper templateName={templateNameRef.current?.value} templateId={templateId} />
                </>
                :
                <>
                    {
                        isNewCreation?
                        <>
                            <CertificateBuilderWrapper templateName={templateNameRef.current?.value} />
                        </>
                        :
                        <>
                            {   
                                templatesPageData.loading ?
                                <Box
                                    p={3}
                                    w="100%"
                                >
                                    <Flex 
                                        w="100%"
                                    >
                                        <Spacer />
                                        <SkeletonCircle size="10" />
                                    </Flex>
                                    <SkeletonText mt="4" noOfLines={10} spacing="4" />

                                </Box>
                                :
                                <>
                                    {
                                        templatesPageData.errorStatus?
                                        <>
                                        </>
                                        :
                                        <>
                                            {
                                                (templatesPageData.data.length === 0)?
                                                <>
                                                    <Center
                                                        w="100%"
                                                        h="100%"
                                                        p={3}
                                                    >
                                                        <Stack>
                                                            <Text>
                                                                It Looks like you haven't build any template yet, Please Create one
                                                            </Text>
                                                            <Button
                                                                colorScheme="teal"
                                                                rightIcon={<FaPlusSquare />}
                                                                ref={createNewTemplateBtnRef}
                                                                onClick={onOpen}
                                                            >
                                                                Create
                                                            </Button>
                                                        </Stack>
                                                    </Center>
                                                </>
                                                :
                                                <>
                                                    <Flex direction="row">
                                                        <Spacer />
                                                        <IconButton
                                                            size="lg"
                                                            fontSize="lg"
                                                            colorScheme="teal"
                                                            onClick={onOpen}
                                                            borderRadius="50%"
                                                            mr="3"
                                                            my="3"
                                                            icon={<FaPlus />}
                                                            aria-label="Create new template"
                                                        />
                                                    </Flex>
                                                    <ResponsiveTable data={createDataForTable(templatesPageData.data)} />
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <Drawer
                                isOpen={isOpen}
                                placement="right"
                                onClose={onClose}
                                finalFocusRef={createNewTemplateBtnRef}
                                initialFocusRef={templateNameRef}
                            >
                                <DrawerOverlay>
                                    <DrawerContent>
                                        <DrawerCloseButton />
                                        <DrawerHeader>What should we call this template</DrawerHeader>

                                        <DrawerBody>
                                            <Input placeholder="Template Name..." ref={templateNameRef} />
                                        </DrawerBody>

                                        <DrawerFooter>
                                            <Button variant="outline" mr={3} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                colorScheme="teal" 
                                                rightIcon={<FaArrowRight />} 
                                                onClick={handleNewTemplateCreateButtonClick}
                                            >
                                                Create
                                            </Button>
                                        </DrawerFooter>
                                    </DrawerContent>
                                </DrawerOverlay>
                            </Drawer>

                            <AlertDialog
                                isOpen={dialougeIsOpen}
                                leastDestructiveRef={cancelRef}
                                onClose={dialougeOnClose}
                            >
                                <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Delete Template
                                    </AlertDialogHeader>
                                    <AlertDialogCloseButton />

                                    <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={dialougeOnClose}>
                                            Cancel
                                        </Button>
                                        <Button 
                                            colorScheme="red" 
                                            isLoading={isLoading}
                                            loadingText="Deleting..."
                                            onClick={ () => {
                                                    handleConfirmDeleteButtonClick(activeTemplateAction.templateId)
                                                }
                                            } 
                                            ml={3}
                                        >
                                            Delete
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>

                            <Drawer
                                isOpen={fullDrawerIsOpen}
                                size="full"
                                onClose={fullDrawerOnClose}
                            >
                                <DrawerOverlay>
                                    <DrawerContent>
                                        <DrawerCloseButton />
                                        <DrawerHeader>
                                            { activeTemplateAction.action==='edit' && <Text>Edit</Text> }
                                            { activeTemplateAction.action==='preview' && <Text>Preview</Text> }
                                        </DrawerHeader>

                                        <DrawerBody>
                                            { activeTemplateAction.action==='preview' && <TemplatesPreview templateData={getTemplateDataFromTemplateId(activeTemplateAction.templateId)} /> }
                                            { activeTemplateAction.action==='edit' && <CertificateBuilder configData={getTemplateDataFromTemplateId(activeTemplateAction.templateId)} templateId={activeTemplateAction.templateId} action="edit" /> }
                                        </DrawerBody>

                                    </DrawerContent>
                                </DrawerOverlay>
                            </Drawer>
                        </>
                    }
                </>
            }
        </>
    )
}

import { useToast } from "@chakra-ui/react";
import { find } from "lodash";
import { customAlphabet } from "nanoid";
import { fontsObject } from "../dataObjects/FontsObject";
const defaultAlphabets = '123456789abcdefghijklmnpqrstuvwxyz-';

const alphabetSimple = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
const alphabetComplex = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz';

const nanoIdSimple = customAlphabet(alphabetSimple, 10);
const nanoIdAverage = customAlphabet(alphabetSimple, 16);
const nanoIdComplex = customAlphabet(alphabetComplex, 20);

export function DisplayToastErrors(title:string, description:string) {
    const toast = useToast()
    toast({
        title: title,
        description: description,
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "bottom-right",
    })
}

export function getNewUniqueId( alphabets:string=defaultAlphabets, size:number=20 ) {
    const nanoid = customAlphabet(alphabets, size)
    return nanoid()
}

export const fetchStyle = function( url:string ) {
    console.log(url);
    
    return new Promise((resolve, reject) => {
      let link = document.createElement('link');
      link.rel = 'stylesheet';
      //@ts-ignore
      link.onload = () => { resolve() };
      link.href = url;
  
      let headScript = document.querySelector('script');
      headScript!.parentNode!.insertBefore(link, headScript);
    });
}

export const fetchStyleSheet = async function (fontName:string) {
    const selectedFontObject = find( fontsObject, ['name', fontName])
    if (selectedFontObject) {
        await fetchStyle(selectedFontObject!.hostPath)
    }
}

export function handleAlignData( alignText:any ): string {
    switch (alignText) {
        case 'left':
            return '0%'
        
        case 'center':
            return '50%'

        case 'right':
            return '100%'
            
        default:
            return '50%'
    }
}

export function handleTextAnchor( alignText:any ): string {
    switch (alignText) {
        case 'left':
            return 'start'
        
        case 'center':
            return 'middle'

        case 'right':
            return 'end'
            
        default:
            return 'middle'
    }
}

export function handleVerticalAlignData( alignText:any ): string {
    switch (alignText) {
        case 'top':
            return '0%'
        
        case 'middle':
            return '50%'

        case 'bottom':
            return '100%'
            
        default:
            return '50%'
    }
}

export function handleDominantBaseline ( alignText:any ): string {
    switch (alignText) {
        case 'top':
            return 'hanging'
        
        case 'middle':
            return 'middle'

        case 'bottom':
            return 'alphabetic'
            
        default:
            return 'middle'
    }
}

export function convertFirebaseDateObjectTointernationalDateString( originalDate:any ) {
    const offset = originalDate.toDate().getTimezoneOffset()
    const newDate = new Date(originalDate.toDate().getTime() - (offset*60*1000))
    return newDate.toISOString().split('T')[0]
}

export function addSeperater(str:string, seperator:string, positionArray:number[]) {
    positionArray.map((pos, index) => {
        str = str.slice(0, pos+index) + (seperator || '') + str.slice(pos+index)
        return str
    })
    return str
}

export function getUniqueId(latency:'low'|'medium'|'high' = 'low', isSeperater:boolean = true, seperator:string='-', positionArray:number[]=[], alphabet:string|undefined=undefined, size:number=10) {
    let certId = ''

    if (alphabet) {
        const nenoIdCustom = customAlphabet(alphabet, size)
        if(isSeperater){
            certId = addSeperater(nenoIdCustom(), seperator, positionArray);
        } else {
            certId = nenoIdCustom();
        }
    } else {
        switch (latency) {
            case 'low':
                if(isSeperater){
                    certId = addSeperater(nanoIdSimple(), seperator, [3,7]);
                } else {
                    certId = nanoIdSimple();
                }
                break;
    
            case 'medium':
                if(isSeperater){
                    certId = addSeperater(nanoIdAverage(), seperator, [4,8,12]);
                } else {
                    certId = nanoIdAverage();
                }
                break;
    
            case 'high':
                if(isSeperater){
                    certId = addSeperater(nanoIdComplex(), seperator, [5,10,15]);
                } else {
                    certId = nanoIdComplex();
                }
                break;
        }
    }
    return certId;
}





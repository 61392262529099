import { Box, Center, Flex, Skeleton, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import { getNewUniqueId } from '../../utils/CommonUtility'
import CertificateBuilder from '../certificateBuilder/CertificateBuilder'
import firebase from 'firebase/app'

export default function CertificateBuilderWrapper( props:any ) {
    const params = useParams()
    const templateName = props.templateName || 'New Template'
    //@ts-ignore
    const templateId = props.templateId || params.templateId || ''
    const toast = useToast()
    //@ts-ignore
    const [builderState, setBuilderState] = useState({
        data: {},
        loading: true,
        error: {},
        errorStatus: false,
        action: '',
        templateId: templateId
    })
    const { db } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()

    const defaultConfigObject = {
        "verificationLink": {
            displayName: "Verification Link",
            fieldName: "verificationLink",
            type: "text",
            alignment: "center",
            verticalAlignment: "middle",
            fontColor: '#000000',
            fontSize: 100,
            fontName: "Arial"
        },
        "qrCode": {
            displayName: "QR Code",
            fieldName: "qrCode",
            type: "qr",
            width: 200,
            height: 200,
        }
    }

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    useEffect(() => {
        (
            async () => {
                let templetDocRef = db.collection("templates")
                if (!builderState.templateId) {
                    const newTemplateId = getNewUniqueId()
                    templetDocRef = templetDocRef.doc(newTemplateId)
                    const firebaseObject = {
                        userId: currentUser.uid,
                        templateId: newTemplateId,
                        templateName: templateName,
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        data: defaultConfigObject,
                        active: true
                    }
                    await templetDocRef.set(firebaseObject)
                    window.history.replaceState({}, "New Template", window.location.href.replace(/\/+$/, "")+'/'+newTemplateId)
                    setBuilderState({
                        data: firebaseObject,
                        loading: false,
                        error: {},
                        errorStatus: false,
                        action: 'new',
                        templateId: newTemplateId
                    })
                    return
                }
                templetDocRef = templetDocRef.doc(builderState.templateId)
                try {
                    let cloudDoc = await templetDocRef.get()
                    if (cloudDoc.exists) {
                        const dataFromFirebase = cloudDoc.data()
                        setBuilderState({
                            data: dataFromFirebase,
                            loading: false,
                            error: {},
                            errorStatus: false,
                            action: 'edit',
                            templateId: builderState.templateId
                        })
                    }
                } catch (error) {
                    setBuilderState({
                        data: {},
                        loading: false,
                        error,
                        errorStatus: true,
                        action: '',
                        templateId: builderState.templateId
                    })
                    displayToastErrors(error.code?error.code:'Unable to fetch template', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
                }
            }
        )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db])

    function firebaseErrorHandler(errorCode:string) {
        switch (errorCode) {
            case "permission-denied":
                return(
                    <Center w="100%" h="100%">
                        <Stack>
                            <Text>
                                Sorry, Lookes like the template Id either does not exist, Or it doesn't belong to you
                            </Text>
                        </Stack>
                    </Center>
                )
        
            default:
                break;
        }
    }

    return (
        <>
            {
                builderState.loading?
                <Flex direction={["column", "row", null, null]} h="100%">
                    <Box
                        w={["100%", "60%", "70%"]} 
                        maxW={["100%", "60%", "70%"]} 
                        minW={["100%", "60%", "70%"]} 
                        h={["60%", "100%", null]}
                        maxH={["60%", "100%", null]}
                        minH={["60%", "100%", null]}
                        p={3}
                    >
                        <Skeleton w="100%" h="100%" />
                    </Box>
                    <Stack w="100%" p={3} overflowY="auto" >
                        <Flex direction="row" justifyContent="center" >
                            <Skeleton
                                w="60%"
                                height="40px"
                            />
                        </Flex>
                        <Skeleton
                            height="30px"
                        />
                        <Skeleton
                            height="30px"
                        />
                        <Skeleton
                            height="30px"
                        />
                        <Flex direction="row" justifyContent="center" >
                            <Stack w="60%">
                                <Skeleton
                                    height="40px"
                                />
                                <Skeleton
                                    height="40px"
                                />
                            </Stack>
                        </Flex>
                    </Stack>
                </Flex>
                :
                <>
                    {
                        builderState.errorStatus?
                        <>
                            {
                                //@ts-ignore
                                firebaseErrorHandler(builderState.error.code)
                            }
                        </>
                        :
                        <>
                            <CertificateBuilder configData={builderState.data} templateId={builderState.templateId} action={builderState.action} templateName={templateName} />
                        </>
                    }
                </>
            }
        </>
    )
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Editable, EditableInput, EditablePreview, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Image, Input, Spacer, Stack, Text, useBreakpointValue, useDisclosure, useToast, VStack, Wrap } from '@chakra-ui/react'
import { debounce, map } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaEdit, FaPlus } from 'react-icons/fa'
import Moveable from "react-moveable"
import './CertificateBuilder.css'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import DrawConfigBoxItem from '../../components/drawConfigBoxItem/DrawConfigBoxItem'
import { fontsObject } from '../../dataObjects/FontsObject'
import Counter from '../../components/counter/Counter'
import { useAuth } from '../../contexts/AuthContext'
import { fetchStyleSheet, handleAlignData, handleDominantBaseline, handleTextAnchor, handleVerticalAlignData } from '../../utils/CommonUtility'
import firebase from 'firebase/app'
import WrapperBox from '../../components/wrapperBox/WrapperBox'

export default function CertificateBuilder( props:any ) {

    const { configData, templateId, templateName } = props

    const hiddenPicRef = useRef<HTMLInputElement>(null)
    const finalRef = useRef<HTMLButtonElement>(null)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isDataSaving, setIsDataSaving] = useState(false)
    const [shouldFirebaseDataUpdate, setShouldFirebaseDataUpdate] = useState(false)
    const [isImageUploadedToCloud, setIsImageUploadedToCloud] = useState(true)
    const [isImageFirstRender, setIsImageFirstRender] = useState(false)
    const [isConfigItemsLoadingDone, setIsConfigItemsLoadingDone] = useState(false)
    const forceUpdate = React.useCallback(() => {
        //write code here to rerender movables
        console.log('window resize')
    }, []);

    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { calculateSidebarWidth, headerHeight, footerHeight, db, storage } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()

    const templateDocRef = db.collection("templates").doc(templateId)

    const breakPointStateDrawerPlacement = useBreakpointValue({
        base: "bottom",
        sm: "right",
    }) as unknown as "bottom" | "right" | "top" | "left" | undefined

    const defaultConfigObject = {
        "verificationLink": {
            displayName: "Verification Link",
            fieldName: "verificationLink",
            type: "text",
            alignment: "center",
            verticalAlignment: "middle",
            fontColor: '#000000',
            fontSize: 40,
            fontName: "Arial",
            required: true,
        },
        "qrCode": {
            displayName: "QR Code",
            fieldName: "qrCode",
            type: "qr",
            width: 50,
            height: 50,
            required: true,
        }
    }

    const [configObject, setConfigObject] = useState(configData?.data || defaultConfigObject)
    const [photoURL, setPhotoURL] = useState(configData?.imgUrl || '')
    const [templateNameVal, setTemplateNameVal] = useState(templateName || configData.templateName || '')

    const schema = yup.object().shape({
        displayName: yup.string().min(3).required(),
        fieldName: yup.string().min(3).required(),
    });
      
    type NewConfigBoxItemFormInputs = {
        displayName: string;
        fieldName: string;
    };

    const { register, handleSubmit, errors } = useForm<NewConfigBoxItemFormInputs>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    function getAdjustedConfigObject(actualConfigObject:any) {
        const imgElement = document.getElementById('templateImage') as HTMLImageElement

        if (!imgElement) {
            return actualConfigObject
        }

        const imgActualHeight = imgElement.naturalHeight
        const imgActualWidth = imgElement.naturalWidth
        const imgRenderedHeight = imgElement.offsetHeight
        const imgRenderedWidth = imgElement.offsetWidth
        const imgRenderedLeftPoint = imgElement.offsetLeft
        const imgRenderedTopPoint = imgElement.offsetTop
        let adjustedConfigObject = {}

        map(actualConfigObject, ( configItem:any ) => {
            const configItemLeft = configItem.left
            const configItemTop = configItem.top
            const configItemWidth = configItem.width
            const configItemHeight = configItem.height

            //@ts-ignore
            adjustedConfigObject[configItem.fieldName] = {
                ...configItem,
                left: (configItemLeft/(imgActualWidth/imgRenderedWidth))+(imgRenderedLeftPoint),
                top: (configItemTop/(imgActualHeight/imgRenderedHeight))+imgRenderedTopPoint,
                width: configItemWidth/(imgActualWidth/imgRenderedWidth),
                height: configItemHeight/(imgActualHeight/imgRenderedHeight),
                ...configItem.fontSize && { fontSize: configItem.fontSize/(imgActualWidth/imgRenderedWidth) }
            }
        })
        return adjustedConfigObject
    }

    function handleEditPicClick() {
        hiddenPicRef.current?.click()
    }

    function isFileImage(file:any) {
        return file && file['type'].split('/')[0] === 'image';
    }

    function handlePicChange() {
        if (isFileImage(hiddenPicRef?.current?.files![0])) {
            setIsImageLoaded(false)
            setShouldFirebaseDataUpdate(true)
            setPhotoURL(URL.createObjectURL(hiddenPicRef?.current?.files![0]))
            setIsImageUploadedToCloud(false)
        } else {
            toast({
                title: "Not an image",
                description: "The file you selected is not an image file. Please use only images",
                status: "error",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
        }
    }

    const addConfigBoxItem = async (values: NewConfigBoxItemFormInputs) => {
        setConfigObject(
            {
                ...configObject,
                [values.fieldName]: {
                    ...values,
                    type: "text",
                    alignment: "center",
                    verticalAlignment: "middle",
                    fontColor: '#000000',
                    fontSize: 40,
                    required: true,
                }
            }
        )
        onClose()

    }

    function sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms || 1000));
    }

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    const handleSaveButtonClick = async ( e:any, finalSave=false ) => {
        setIsDataSaving(true)
        if (!shouldFirebaseDataUpdate) {
            await sleep(2000)
            setIsDataSaving(false)
            return true
        }
        await handleSaveToFirebase(e)
        setIsDataSaving(false)
        return true
    }

    const handleSaveToFirebase = async ( e:any ) => {
        let cloudImgURL;
        
        if (!isImageUploadedToCloud) {
            const imageRef = storage.ref().child('users').child(currentUser.uid).child('images/templates').child(templateId)
            
            try {
                let storageResult = await imageRef.put(hiddenPicRef?.current?.files![0])
                cloudImgURL = await storageResult.ref.getDownloadURL()
                setIsImageUploadedToCloud(true)
            } catch (error) {
                displayToastErrors(error.code?error.code:'Not Updated', 
                    error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
            }
        }
        
        const imgElement = document.getElementById('templateImage') as HTMLImageElement

        if (!imgElement) {
            return false
        }

        const imgActualHeight = imgElement.naturalHeight
        const imgActualWidth = imgElement.naturalWidth
        const imgRenderedHeight = imgElement.offsetHeight
        const imgRenderedWidth = imgElement.offsetWidth
        const imgRenderedLeftPoint = imgElement.offsetLeft
        const imgRenderedTopPoint = imgElement.offsetTop
        let calculatedConfigObject = {}

        map(configObject, ( configItem:any ) => {
            const configItemLeft = configItem.left || imgRenderedLeftPoint
            const configItemTop = configItem.top || imgRenderedTopPoint
            const configItemWidth = configItem.width || 100
            const configItemHeight = configItem.height || 50

            //@ts-ignore
            calculatedConfigObject[configItem.fieldName] = {
                ...configItem,
                left: (configItemLeft-imgRenderedLeftPoint)*(imgActualWidth/imgRenderedWidth),
                top: (configItemTop-imgRenderedTopPoint)*(imgActualHeight/imgRenderedHeight),
                width: configItemWidth*(imgActualWidth/imgRenderedWidth),
                height: configItemHeight*(imgActualHeight/imgRenderedHeight),
                ...configItem.fontSize && { fontSize: configItem.fontSize*(imgActualWidth/imgRenderedWidth) }
            }
        })

        let firebaseObject = {
            userId: currentUser.uid,
            templateId: templateId,
            templateName: templateNameVal,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            data: calculatedConfigObject,
            ...cloudImgURL && {imgUrl: cloudImgURL},
        }
        setShouldFirebaseDataUpdate(false)
        try {
            await templateDocRef.set(firebaseObject, { merge: true })
        } catch (error) {
            setShouldFirebaseDataUpdate(true)
            displayToastErrors(error.code?error.code:'Unable to save data', 
                        error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
    }

    const onImageLoadHandler = ( e:any ) => {
        if (!isImageFirstRender) {
            setConfigObject(getAdjustedConfigObject(configObject))
            setIsImageFirstRender(true)
        }
        setIsImageLoaded(true)
    }

    const onConfigItemUpdate = useCallback( ( updatedConfigBoxItem:any ) => {
        //@ts-ignore
        configObject[updatedConfigBoxItem.fieldName] = updatedConfigBoxItem
        setConfigObject({ ...configObject })
    }, [configObject])

    useEffect(() => {
        const onStateChangeHook = () => {
            setShouldFirebaseDataUpdate(true)
        }
        return () => {
            onStateChangeHook()
        }
    }, [configObject])

    useEffect(() => {
        (()=>{
            map(configData.data, async (configItem:any) => {
                if(configItem.fontName){
                    await fetchStyleSheet(configItem.fontName)
                }
            })
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.addEventListener("resize", debounce( forceUpdate, 450 ));
        return () => window.removeEventListener("resize", debounce( forceUpdate, 450 ));
    }, [forceUpdate]);
    
    return (
        <Flex direction={["column", "row", null, null]} h="100%">
            <Box 
                w={["100%", "60%", "70%"]} 
                maxW={["100%", "60%", "70%"]} 
                minW={["100%", "60%", "70%"]} 
                h={["60%", "100%", null]}
                maxH={["60%", "100%", null]}
                minH={["60%", "100%", null]}
                p={3}
            >
                <Center 
                    h={["calc(100% - 48px)", "calc(100% - 64px)", "calc(100% - 96px)", null]} 
                    border={photoURL?"":"5px dashed blue"} 
                    id="mycanvas"
                >
                    <Input type="file" id="hiddenProfilePic" display="none" ref={hiddenPicRef} onChange={handlePicChange} />
                    {
                        photoURL?
                        <>
                            {/* <Image src="/images/image-loading.svg" display={isImageLoaded?"none":"inherit"} /> */}
                            <Image 
                                maxH="100%"
                                maxW="100%"
                                src={photoURL} 
                                onLoad={(e) => {onImageLoadHandler(e)}}
                                id="templateImage"
                                backgroundImage="url('/images/image-loading.svg')"
                                backgroundRepeat="no-repeat"
                                backgroundAttachment="fixed"
                                backgroundPosition="center"
                            />
                        </>:
                        <>
                            <VStack spacing={3}>
                                <Text>
                                    Drop certificate
                                </Text>
                                <Button variant="solid" onClick={handleEditPicClick} >
                                    Upload Template
                                </Button>
                            </VStack>
                        </>
                    }

                    {
                        isImageFirstRender &&
                        <>
                            {
                                map(configObject, ( configItem:any ) => {
                                    return (
                                        <Box key={"moveBox"+configItem.fieldName}>
                                            {    
                                                <>
                                                    <Box
                                                        className={`${configItem.fieldName}`}
                                                        position="absolute"
                                                        display={isImageLoaded?"inherit":"none"}
                                                        w={ configItem.width?`${configItem.width}px`:undefined}
                                                        h={ configItem.height?`${configItem.height}px`:undefined}
                                                        top={ configItem.top?configItem.top+'px': [`calc(((((100vh - ${headerHeight + footerHeight}px ) * 0.6 ) - 48px) * 0.5) + 23px)`, `calc((((100vh - ${headerHeight + footerHeight}px ) - 64px) * 0.5) + 23px)`, `calc((((100vh - ${headerHeight + footerHeight}px ) - 96px) * 0.5) + 23px)`]}
                                                        left={ configItem.left?configItem.left+'px': [`calc((( 100vw * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`, `calc((((( 100vw - ${calculateSidebarWidth()}px ) * 0.6 ) * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`, `calc((((( 100vw - ${calculateSidebarWidth()}px ) * 0.7 ) * 0.5 ) - 25px ) + ${calculateSidebarWidth()}px )`]}
                                                    >
                                                        {
                                                            (configItem.type === 'qr')?
                                                            <Image 
                                                                minWidth="100%"
                                                                src="/images/qr-code-sample.png" 
                                                            />:
                                                            <svg 
                                                                height="100%" 
                                                                width="100%" 
                                                            >
                                                                <text x={handleAlignData( configItem.alignment )} y={handleVerticalAlignData( configItem.verticalAlignment )} dominantBaseline={handleDominantBaseline( configItem.verticalAlignment )} textAnchor={handleTextAnchor( configItem.alignment )} fontFamily={ configItem.fontName } fontSize={ configItem.fontSize } fill={ configItem.fontColor || "#aaaaaa" }>{configItem.displayName}</text>
                                                            </svg>
                                                        }
                                                    </Box>
                                                
                                                    {   
                                                        isConfigItemsLoadingDone &&
                                                        <Moveable
                                                            //@ts-ignore
                                                            target={document.querySelector(`.${configItem.fieldName}`)}
                                                            // container={document.getElementById('templateImage')}
                                                            draggable={true}
                                                            throttleDrag={0}
                                                            keepRatio={(configItem.fieldName === 'qrCode')?true:false}
                                                            onDrag={({
                                                                target,
                                                                transform, top, left
                                                            }) => {
                                                                target!.style.top = top+'px';
                                                                target!.style.left = left+'px';
                                                            }}
                                                            onDragEnd={( dataProps ) => {
                                                                const { target } = dataProps;
                                                                setConfigObject({
                                                                    ...configObject,
                                                                    [configItem.fieldName]: {
                                                                        ...configItem,
                                                                        top: target.getBoundingClientRect().top || configItem.top || 200,
                                                                        left: target.getBoundingClientRect().left || configItem.left || 200,
                                                                    }
                                                                })
                                                            }}

                                                            resizable={true}
                                                            onResize={( dataProps ) => {
                                                                const { target, width, height, delta, direction } = dataProps;
                                                                delta[0] && (target!.style.width = `${width}px`);
                                                                delta[1] && (target!.style.height = `${height}px`);
                                                                if(direction[0] < 0) {
                                                                    delta[0] && (target!.style.left = `${ target.getBoundingClientRect().left - delta[0]}px`);
                                                                }
                                                                if(direction[1] < 0) {
                                                                    delta[1] && (target!.style.top = `${ target.getBoundingClientRect().top - delta[1]}px`);
                                                                }
                                                            }}
                                                            onResizeEnd={( dataProps ) => {
                                                                const { target } = dataProps
                                                                setConfigObject({
                                                                    ...configObject,
                                                                    [configItem.fieldName]: {
                                                                        ...configItem,
                                                                        width: parseFloat(target!.style.width) || configItem.width,
                                                                        height: parseFloat(target!.style.height) || configItem.height,
                                                                        top: target.getBoundingClientRect().top || configItem.top,
                                                                        left: target.getBoundingClientRect().left || configItem.left,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    }
                                                </>
                                            }
                                        </Box>
                                    )
                                })
                            }
                            <Image 
                                w="1px"
                                h="1px"
                                display="none"
                                src="/images/zero_pixel_image.png" 
                                onLoad={ () => setIsConfigItemsLoadingDone(true) }
                            />
                        </>
                    }
                </Center>
                <Box 
                    py={1}
                    maxH={["48px", "64px", "96px"]}
                >
                    <Flex direction="row" >
                        <Spacer />
                        {
                            isImageLoaded &&
                            <Button leftIcon={<FaEdit />} variant="solid" colorScheme="teal" size="sm" onClick={handleEditPicClick} >
                                Change Template
                            </Button>
                        }
                        <Spacer />
                    </Flex>                
                </Box>
            </Box>
            <Box flexGrow={1} overflowY="auto" p={3} >
                <WrapperBox
                    placeholderText="Template Name (Click To Edit)"
                    PropsToBox={{
                        mb: 3
                    }}
                >
                    <Editable 
                        defaultValue={templateNameVal} 
                        fontSize="2xl"
                        textAlign="center"
                        onSubmit={ (nextValue: string) => {
                                setTemplateNameVal(nextValue)
                                setShouldFirebaseDataUpdate(true)
                            }
                        }
                    >
                        <EditablePreview />
                        <EditableInput />
                    </Editable>
                </WrapperBox>
                <WrapperBox
                    placeholderText="Components"
                >
                    <Flex direction="row" >
                        <Spacer />
                        <Button rightIcon={<FaPlus />} colorScheme="teal" variant="solid" mb="3" onClick={onOpen} ref={finalRef} >
                            Add New
                        </Button>

                        <Drawer
                            isOpen={isOpen}
                            placement={breakPointStateDrawerPlacement}
                            onClose={onClose}
                            finalFocusRef={finalRef}
                            size="md"
                        >
                            <DrawerOverlay>
                                <DrawerContent maxW={["100vw", `calc( (100vw - ${calculateSidebarWidth()}px) * 0.3 )`, null, null]}>
                                    <DrawerCloseButton size="lg" mt={1} />
                                    <DrawerHeader borderBottomWidth="1px">
                                        Create new field
                                    </DrawerHeader>

                                    <DrawerBody className="style-3">
                                        <form>
                                            <FormControl
                                                isInvalid={!!errors?.displayName}
                                                errortext={errors?.displayName?.message}
                                                isRequired
                                            >
                                                <FormLabel>Display Name</FormLabel>
                                                <Input name="displayName" ref={register} placeholder="Display Name" />
                                                <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
                                                <FormHelperText>
                                                    What sould we call this field?
                                                </FormHelperText>
                                            </FormControl>

                                            <FormControl 
                                                mt={4}
                                                isInvalid={!!errors?.fieldName}
                                                errortext={errors?.fieldName?.message}
                                                isRequired
                                            >
                                                <FormLabel>Field Name</FormLabel>
                                                <Input name="fieldName" placeholder="Field Name" ref={register} />
                                                <FormErrorMessage>{errors?.fieldName?.message}</FormErrorMessage>
                                                <FormHelperText>
                                                    How will you call this name in API?
                                                </FormHelperText>
                                            </FormControl>
                                        </form>
                                    </DrawerBody>
                                    <DrawerFooter borderTopWidth="1px">
                                        <Button 
                                            colorScheme="blue" 
                                            mr={3}
                                            onClick={handleSubmit(addConfigBoxItem)}
                                        >
                                            Save
                                        </Button>
                                        <Button onClick={onClose}>Cancel</Button>
                                    </DrawerFooter>
                                </DrawerContent>
                            </DrawerOverlay>
                        </Drawer>
                    </Flex>
                    <Accordion allowToggle >
                        {
                            map(configObject, ( configItem:any ) => {
                                return (
                                    <AccordionItem key={"configBoxItem"+configItem.fieldName} >
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    {configItem.displayName}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Wrap >
                                                {
                                                    <DrawConfigBoxItem
                                                        data={configItem}
                                                        onUpdate={onConfigItemUpdate}
                                                        fontsObject={fontsObject}
                                                    />
                                                }
                                            </Wrap>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            })
                        }
                    </Accordion>
                </WrapperBox>
                <Flex direction="row" alignItems="center" mt="3">
                    <Spacer />
                    <Button
                        isLoading={isDataSaving}
                        loadingText="Saving..."
                        colorScheme="blue"
                        onClick={ async ( e:any ) => {
                            await handleSaveButtonClick(e)
                        }}
                    >
                        <Stack spacing={0}>
                            <Text fontSize="lg" >
                                Save
                            </Text>
                            <Text fontSize="xs">
                                {
                                    shouldFirebaseDataUpdate?
                                    <>
                                        Auto save in {" "}
                                        <Counter
                                            ts={520} 
                                            ss={1} 
                                            callBackOnTimerEnd={async ( e:any ) => {
                                                    await handleSaveButtonClick(e)
                                                } 
                                            }
                                        />
                                        {" "} s
                                    </>:
                                    "Nothing to save"
                                }
                            </Text>
                        </Stack>
                    </Button>
                    <Spacer />
                </Flex>
            </Box>
        </Flex>
    )
}

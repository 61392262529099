import { Box, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Flex, Text, Divider, Stack } from '@chakra-ui/react'
import React from 'react'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'

export default function ResponsiveTable( params:any ) {
    const { data, tableCaption, tablePropsToPass, containerPropsToPass, ...propsToPass } = params
    const { deviceType } = GlobalDashBoardHandler()

    const LandscapeDisplay = () => {
        return(
            <Box
                {...containerPropsToPass}
                overflowX="auto"
                p={3}
            >
                <Table colorScheme="teal" {...tablePropsToPass} {...propsToPass} >
                    { tableCaption && <TableCaption>{tableCaption}</TableCaption> }
                    <Thead>
                        <Tr>
                            {
                                data.keys.map((key:string, index:number) => {
                                    return (<Th key={`${index}header`}>{data.header[key]}</Th>)
                                })
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data.body.map((item:any, bodyItemIndex:number) => {
                                return (
                                    <Tr key={item.keyId || bodyItemIndex}>
                                        {
                                            data.keys.map((key:string, index:number) => {
                                                return (<Td key={`${item.keyId || bodyItemIndex}-${index}row`}>{item[key]}</Td>)
                                            })
                                        }
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Box>
        )
    }

    const PortraitDisplay = () => {
        return(
            <Stack
                {...containerPropsToPass}
                p={3}
                spacing={5}
            >
                {
                    data.body.map((item:any, bodyItemIndex:number) => {
                        return (
                            <Box boxShadow="md" p={3} rounded="3xl" borderWidth={1} key={item.keyId || bodyItemIndex} >
                                {
                                    data.keys.map((key:string, index:number) => {
                                        return (
                                            <Stack spacing={0} key={`${item.keyId || bodyItemIndex}-${index}row`}>
                                                <Flex direction="row" p="3" minH="60px" alignItems="center">
                                                    <Flex flexBasis="40%" pr="3">
                                                        <Text>
                                                            {
                                                                data.header[key]
                                                            }
                                                        </Text>
                                                    </Flex>
                                                    <Divider orientation="vertical" h="40px" />
                                                    <Flex flexGrow={1} pl={3}>
                                                        {
                                                            item[key]
                                                        }
                                                    </Flex>
                                                </Flex>
                                                {data.keys[index+1] && <Divider/>}
                                            </Stack>
                                        )
                                    })
                                }
                            </Box>
                        )
                    })
                }
            </Stack>
        )
    }

    return (
        <>
            { (deviceType==="landscape") && <LandscapeDisplay /> }
            { (deviceType==="portrait") && <PortraitDisplay /> }
        </>
    )
}

import { FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, InputLeftAddon, InputGroup, InputRightElement, Button, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaEnvelope, FaEye, FaEyeSlash, FaKey, FaUserLock } from 'react-icons/fa'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'

export default function SignupForm() {

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        confirmPassword: yup.string()
                            .oneOf([yup.ref('password'), null], 'Passwords must match')
                            .required('Confirm Password is required'),
    });
      
    type SignupFormInputs = {
        email: string;
        password: string;
        confirmPassword: string;
    };

    const { register, handleSubmit, errors } = useForm<SignupFormInputs>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const { signupByEmail, sendEmailVerificationMail } = useAuth()
    const toast = useToast()
    const history = useHistory()

    const onSubmit = async (values: SignupFormInputs) => {
        setIsButtonLoading(true)
        try {
            await signupByEmail(values.email, values.password)
            await sendEmailVerificationMail()
            toast({
                title: "Account created.",
                description: "We've created your account for you.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsButtonLoading(false)
            history.push("/dashboard")
        } catch (error) {
            toast({
                title: error.code?error.code:"Something went wrong",
                description: error.message?error.message:"Sorry but we are unable to create account at the moment. Please try again after some time.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsButtonLoading(false)
        }
    }

    return (
        <form >
            <FormControl
                isInvalid={!!errors?.email}
                errortext={errors?.email?.message}
                p='4'
                isRequired
            >
                <FormLabel>Email</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaEnvelope color="gray.300" />} />
                    <Input type='email' name='email' placeholder='Email' ref={register} />
                </InputGroup>
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                <FormHelperText>
                    We are obviously giving this straight to Facebook.
                </FormHelperText>
            </FormControl>
            <FormControl
                isInvalid={!!errors?.password?.message}
                errortext={errors?.password?.message}
                px='4'
                pb='4'
                isRequired
            >
                <FormLabel>Password</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaUserLock color="gray.300" />} />
                    <Input ref={register} type={showPassword ? "text" : "password"} placeholder='Password' name='password' />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" mr={-5} variant="ghost" onClick={()=>setShowPassword(!showPassword)}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                <FormHelperText>
                    Keep it strong.
                </FormHelperText>
            </FormControl>
            <FormControl
                isInvalid={!!errors?.confirmPassword?.message}
                errortext={errors?.confirmPassword?.message}
                px='4'
                pb='4'
                isRequired
            >
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaKey color="gray.300" />} />
                    <Input ref={register} type={showConfirmPassword ? "text" : "password"} placeholder='Confirm Password' name='confirmPassword' />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" mr={-5} variant="ghost" onClick={()=>setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
                <FormHelperText>
                    Keep it strong.
                </FormHelperText>
            </FormControl>
            <Button
                onClick={handleSubmit(onSubmit)}
                p='4'
                mx='4'
                mt='2'
                w='90%'
                colorScheme='teal'
                variant='solid'
                disabled={!!errors.email || !!errors.password}
                isLoading={isButtonLoading}
                loadingText="Creating.."
            >
                Sign Up
            </Button>
        </form>
    )
}

import { Box, Button, Checkbox, CircularProgress, CircularProgressLabel, Divider, Flex, Heading, Input, Select, Spacer, Stack, Text, Textarea, useBreakpointValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaPlusSquare } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import { getUniqueId } from '../../utils/CommonUtility'
import WrapperBox from '../wrapperBox/WrapperBox'
import {parse} from 'papaparse'
import firebase from 'firebase/app'
import { difference, find, keys, remove } from 'lodash'

export default function NewCertificateMaker() {

    const { db } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()
    const toast = useToast()
    const [isShowSubmit, setIsShowSubmit] = useState(false)
    const [pageData, setPageData] = useState({
        templatesData: [],
        eventsData: [],
        organisationsData: [],
        loading: false,
        error: {},
        errorStatus: false,
    })
    const [showCSVUploadButton, setShowCSVUploadButton] = useState(false)
    const [fileHolder, setFileHolder] = useState()
    const [urlHolder, setUrlHolder] = useState('')
    const [isValidCSVURL, setIsValidCSVURL] = useState(true)
    const [csvDataHolder, setCsvDataHolder] = useState<object[] | unknown>()
    const [certificateSendPercent, setCertificateSendPercent] = useState(0)
    const [certificateSendState, setCertificateSendState] = useState('')
    const history = useHistory()
    const isFileHaveHeader = useRef<HTMLInputElement>(null)
    const orgRef = useRef<HTMLSelectElement>(null)
    const eventRef = useRef<HTMLSelectElement>(null)
    const templateRef = useRef<HTMLSelectElement>(null)
    const variant = useBreakpointValue({ base: "vertical", md: "horizontal" })

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    const fetchFirebaseDataForGivenCollection = ( collection:string ) => {
        try {
            return db
            .collection(collection)
            .where("userId", "==", currentUser.uid)
            .where("active", "==", true)
            .orderBy("updatedAt", "desc")
            .get()
        } catch (error) {
            setPageData({
                ...pageData,
                loading: false,
                error,
                errorStatus: true,
            })
            displayToastErrors(error.code?error.code:'Unable to fetch events', 
                            error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
    }

    const showSubmit = () => {
        setIsShowSubmit(true)
    }

    const handleFileChange = (e:any) => {
        const file = e.target.files[0]
        if (file) {
            if (file.type === 'application/vnd.ms-excel') {
                setFileHolder(file)
                setShowCSVUploadButton(true)
            } else {
                displayToastErrors('Not a csv file', 'We are supporting only CSV files for now')
            }
        }
    }

    const handleFileURLChange = (e:any) => {
        const url = e.target.value
        if (url.length === 0 || url.split('.').pop() === 'csv') {
            setUrlHolder(url)
            setShowCSVUploadButton(true)
            setIsValidCSVURL(true)
        } else {
            setIsValidCSVURL(false)
            setShowCSVUploadButton(false)
        }
    }

    const handleSendCertificatesClick = async () => {

        let organisationId = orgRef.current?.value
        let eventId = eventRef.current?.value
        let templateId = templateRef.current?.value

        if (!organisationId) {
            displayToastErrors('Organisation is required', 'You have\'t selected any organisation yet, Please select or create one')
            return false
        }

        if (!eventId) {
            displayToastErrors('Event is required', 'You have\'t selected any event yet, Please select or create one')
            // return false
        }

        if (!templateId) {
            displayToastErrors('Template is required', 'You have\'t selected any template yet, Please select or create one')
            return false
        }

        let selectedTemplateData = find(pageData.templatesData, ['templateId', templateRef.current?.value])
        //@ts-ignore
        let selectedTemplateDataKeys = keys(selectedTemplateData!.data)
        //@ts-ignore
        let csvDataKeys = keys(csvDataHolder[0])

        let customTemplateDataKeys = remove(selectedTemplateDataKeys, (val) => {
            if (val !== 'qrCode' && val !== 'verificationLink') {
                return true
            }
        })

        customTemplateDataKeys.push('email')

        if (difference(customTemplateDataKeys, csvDataKeys).length !== 0) {
            displayToastErrors('Templates and CSV data mismatch', `Required fields from template are not in csv uploaded. Required fields are [${customTemplateDataKeys}], While provided fields are [${csvDataKeys}]`)
            // return false
        }

        const jobId = getUniqueId('high')

        console.log(jobId)

        const jobsRef = db.collection('jobs')

        let jobDoc = {
            jobId,
            userId: currentUser.uid,
            templateId,
            eventId,
            organisationId,
            userDetails: csvDataHolder,
            metaData: {
                jobStatus: 'created',
                processedTask: 0,
                completedTask: 0,
                failedTask: 0,
                //@ts-ignore
                totalTask: csvDataHolder!.length,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: currentUser.uid
            }
        }

        try {
            await jobsRef.doc(jobId).set(jobDoc)
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to send certificates', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }

        setCertificateSendState('JobId :: '+jobId+"\nTask Started")

        jobsRef.doc(jobId)
        .onSnapshot((doc:any) => {
            let docData = doc.data()
            let processedTask = docData.metaData.processedTask
            let totalTask = docData.metaData.totalTask
            let failedTask = docData.metaData.failedTask
            setCertificateSendPercent(processedTask/totalTask*100)
            setCertificateSendState(certificateSendState+`\nTask ${Math.ceil(processedTask/totalTask*100)}% complete`)
            if (totalTask === processedTask) {
                setCertificateSendState( certificateSendState +`\nJob complete \nFind job details below \nJobId :: ${jobId} \nFailed Task :: ${failedTask} \nCompleted task :: ${processedTask-failedTask}`)
            }
        });

    }

    const handleUploadCSVButtonClick = async () => {
        if( fileHolder ) {
            parse(fileHolder as unknown as File, {
                worker: true,
                header: isFileHaveHeader.current!.checked,
                skipEmptyLines: true,
                complete: (result) => {
                    setCsvDataHolder(result.data)
                    showSubmit()
                }
            });
        } else if (urlHolder){
            parse(urlHolder, {
                worker: true,
                header: true,
                download:true,
                complete: (result) => {
                    setCsvDataHolder(result.data)
                    showSubmit()
                }
            });
        }
    }

    useEffect(() => {
        ( async () => {

            let tempDataHolder:Object[] = []
            let tempPromisHolder = await Promise.all(
                [
                    await fetchFirebaseDataForGivenCollection('templates'),
                    await fetchFirebaseDataForGivenCollection('events'),
                    await fetchFirebaseDataForGivenCollection('organisations')
                ]
            )

            tempPromisHolder.forEach((data) => {
                let subTempArr:Object[] = []
                data.forEach((doc:Object) => {
                    //@ts-ignore
                    subTempArr.push(doc.data())
                })
                tempDataHolder.push(subTempArr)
            })

            setPageData({
                ...pageData,
                templatesData: tempDataHolder[0] as never[],
                eventsData: tempDataHolder[1] as never[],
                organisationsData: tempDataHolder[2] as never[],
            })
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Box p={3} >
                <WrapperBox
                    placeholderText="Choose event details"
                >
                    <Flex direction={['column',null,'row']} justifyContent="space-between" minH="100px" alignItems="center" >
                        <Box p={3}>
                            <Stack>
                                <Heading as="h5" size="sm">
                                    Choose Organisation
                                </Heading>
                                {
                                    (pageData.organisationsData.length === 0)?
                                    <>
                                        <Text>No templates found</Text>
                                        <Button
                                            colorScheme="teal"
                                            rightIcon={<FaPlusSquare />}
                                            size="sm"
                                            onClick={() => {
                                                history.push('/dashboard/templates')
                                            }}
                                        >
                                            Create One
                                        </Button>
                                    </>
                                    :
                                    <Select ref={orgRef}>
                                        {
                                            pageData.organisationsData.map( ( item:any ) => {
                                                return (
                                                    <option value={item.organisationId} key={`"${item.organisationId}"`}> {item.organisationName} </option>
                                                )
                                            })
                                        }
                                    </Select>
                                }
                            </Stack>
                        </Box>
                        {
                            (variant === 'horizontal')?
                            <Divider orientation="vertical" h={["0px", null, "100px", null]} />
                            :
                            <Divider orientation="horizontal" w={["100%", null, "0%", null]} />
                        }
                        <Box p={3}>
                            <Stack>
                                <Heading as="h5" size="sm">
                                    Choose Event
                                </Heading>
                                {
                                    (pageData.eventsData.length === 0)?
                                    <>
                                        <Text>No event found</Text>
                                        <Button
                                            colorScheme="teal"
                                            rightIcon={<FaPlusSquare />}
                                            size="sm"
                                            onClick={() => {
                                                history.push('/dashboard/events')
                                            }}
                                        >
                                            Create One
                                        </Button>
                                    </>
                                    :
                                    <Select ref={eventRef}>
                                        {
                                            pageData.eventsData.map( ( item:any ) => {
                                                return (
                                                    <option value={item.eventId} key={`"${item.eventId}"`}> {item.eventName} </option>
                                                )
                                            })
                                        }
                                    </Select>
                                }
                            </Stack>
                        </Box>
                        {
                            (variant === 'horizontal')?
                            <Divider orientation="vertical" h={["0px", null, "100px", null]} />
                            :
                            <Divider orientation="horizontal" w={["100%", null, "0%", null]} />
                        }
                        <Box p={3}>
                            <Stack>
                                <Heading as="h5" size="sm">
                                    Choose Template
                                </Heading>
                                {
                                    (pageData.templatesData.length === 0)?
                                    <>
                                        <Text>No templates found</Text>
                                        <Button
                                            colorScheme="teal"
                                            rightIcon={<FaPlusSquare />}
                                            size="sm"
                                            onClick={() => {
                                                history.push('/dashboard/templates')
                                            }}
                                        >
                                            Create One
                                        </Button>
                                    </>
                                    :
                                    <Select ref={templateRef}>
                                        {
                                            pageData.templatesData.map( ( item:any ) => {
                                                return (
                                                    <option value={item.templateId} key={`"${item.templateId}"`}> {item.templateName} </option>
                                                )
                                            })
                                        }
                                    </Select>
                                }
                            </Stack>
                        </Box>
                    </Flex>
                </WrapperBox>
                <WrapperBox
                    placeholderText="Provide data (Local file takes precedence over remote file)"
                    mt={8}
                >
                    <Flex direction={['column',null,'row']} justifyContent="space-between" alignItems="center" borderBottomWidth={1}>
                        <Box m={3} w={(variant === 'horizontal')? "40%" : undefined} >
                            <Heading as="h5" size="sm" mb={3}>
                                Choose local file
                            </Heading>
                            <Input 
                                type="file" 
                                p="4px" 
                                accept=".csv"
                                onChange={(e) => {
                                    handleFileChange(e)
                                }}
                            />
                        </Box>
                        {
                            (variant === 'horizontal')?
                            <Divider orientation="vertical" h={["0px", null, "100px", null]} />
                            :
                            <Divider orientation="horizontal" mt={6} w={["100%", null, "0%", null]} />
                        }
                        <Box p={2} px={3} position={(variant === 'horizontal')? "absolute" : undefined} ml={(variant === 'horizontal')? "calc(50% - 78px)" : undefined} mt={(variant === 'vertical')? "-23px" : undefined} border="1px solid" borderRadius="50%">
                            Or
                        </Box>
                        <Box m={3} w={(variant === 'horizontal')? "40%" : undefined} >
                            <Heading as="h5" size="sm" mb={3}>
                                Enter remote URL
                            </Heading>
                            <Input 
                                type="url" 
                                isInvalid={!isValidCSVURL}
                                errorBorderColor="red.300"
                                onChange={(e) => {
                                    handleFileURLChange(e)
                                }}
                            />
                            {
                                !isValidCSVURL &&
                                <Text as="sub" color="red.300" >
                                    Please enter a valid CSV file URL
                                </Text>
                            }
                        </Box>
                    </Flex>
                    <Flex direction={['column',null,'row']} justifyContent="space-between" alignItems="center" mt={4}>
                        <Spacer/>
                        <Checkbox ref={isFileHaveHeader} colorScheme="teal" defaultChecked={true} hidden={true}>
                            Does file include header
                        </Checkbox>
                        <Button
                            isDisabled={!showCSVUploadButton}
                            colorScheme="teal"
                            ml={5}
                            onClick={() => {
                                handleUploadCSVButtonClick()
                            }}
                        >
                            Upload CSV
                        </Button>
                        <Spacer/>
                    </Flex>
                </WrapperBox>
                <Flex>
                    <Spacer />
                    {
                        isShowSubmit &&
                        <Button 
                            mt={5} 
                            colorScheme="teal" 
                            onClick={() => {
                                handleSendCertificatesClick()
                            }}
                        >
                            Send Certificates
                        </Button>
                    }
                    <Spacer />
                </Flex>
                {
                    certificateSendState && 
                    <WrapperBox 
                        mt={8}
                        placeholderText="Certificates Status"
                    >
                        <Flex direction={['column',null,'row']} justifyContent="space-between" alignItems="center" mt={4}>
                            <Textarea
                                isReadOnly={true}
                                colorScheme="teal"
                                size="sm"
                                resize="none"
                                mr={[0, null, 8, null]}
                                mb={[8, null, 0, null]}
                                value={certificateSendState}
                            />
                            <CircularProgress value={Math.ceil(certificateSendPercent)} color="green.400" size="80px">
                                <CircularProgressLabel>{`${Math.ceil(certificateSendPercent)}%`}</CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                    </WrapperBox>
                }
            </Box>
        </>
    )
}

import { Flex, IconButton, Link, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FaFacebook, FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <Flex direction="row" flexGrow={1} alignItems="center" boxShadow="md" h="100%">
            <Link href="https://givemycertificate.com" isExternal ml={5}>
                © Give My Certificate
            </Link>
            <Spacer />
            Follow us on 
            <IconButton
                as={Link}
                href="https://givemycertificate.com"
                isExternal
                size="md"
                fontSize="lg"
                colorScheme="facebook"
                mx="2"
                icon={<FaFacebook />}
                aria-label="Go to facebook page"
            />

            <IconButton
                as={Link}
                href="https://givemycertificate.com"
                isExternal
                size="md"
                fontSize="lg"
                colorScheme="twitter"
                marginRight={4}
                icon={<FaTwitter />}
                aria-label="Go to twitter page"
            />
        </Flex>
    )
}

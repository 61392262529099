import { FaHome, FaCalendarAlt, FaPeopleCarry, FaCertificate } from 'react-icons/fa'
import { HiOutlineTemplate } from 'react-icons/hi'
// import { AiOutlineApi } from 'react-icons/ai'

export const menuData = {
    data: [
        {
            id: 1,
            name: "Home",
            link: "/dashboard",
            badge: "new",
            icon: FaHome
        },
        {
            id: 2,
            name: "Organisations",
            link: "/dashboard/organisations",
            badge: "new",
            icon: FaPeopleCarry
        },
        {
            id: 3,
            name: "Events",
            link: "/dashboard/events",
            badge: "new",
            icon: FaCalendarAlt
        },
        {
            id: 4,
            name: "Templates",
            link: "/dashboard/templates",
            badge: "new",
            icon: HiOutlineTemplate
        },
        {
            id: 5,
            name: "Certificates",
            link: "/dashboard/certificates",
            badge: "new",
            icon: FaCertificate
        },
        // {
        //     id: 6,
        //     name: "Api Center",
        //     link: "/dashboard/apicenter",
        //     badge: "new",
        //     icon: AiOutlineApi
        // },
    ]
}
export const fontsObject = [
    {
        id: 1,
        name: "Akaya Telivigala",
        displayName: "Akaya Telivigala",
        fontFamily: ['Akaya Telivigala', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap"
    },
    {
        id: 2,
        name: "Stick",
        displayName: "Stick",
        fontFamily: ['Stick', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Stick&display=swap"
    },
    {
        id: 3,
        name: "Oswald",
        displayName: "Oswald",
        fontFamily: ['Oswald', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Oswald&display=swap"
    },
    {
        id: 4,
        name: "Akaya Kanadaka",
        displayName: "Akaya Kanadaka",
        fontFamily: ['Akaya Kanadaka', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&display=swap"
    },
    {
        id: 5,
        name: "Teko",
        displayName: "Teko",
        fontFamily: ['Teko', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Teko&display=swap"
    },
    {
        id: 6,
        name: "Oi",
        displayName: "Oi",
        fontFamily: ['Oi', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Oi&display=swap"
    },
    {
        id: 7,
        name: "Nunito Sans",
        displayName: "Nunito Sans",
        fontFamily: ['Nunito Sans', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap"
    },
    {
        id: 8,
        name: "Ballet",
        displayName: "Ballet",
        fontFamily: ['Ballet', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Ballet&display=swap"
    },
    {
        id: 9,
        name: "Yanone Kaffeesatz",
        displayName: "Yanone Kaffeesatz",
        fontFamily: ['Yanone Kaffeesatz', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap"
    },
    {
        id: 10,
        name: "Bebas Neue",
        displayName: "Bebas Neue",
        fontFamily: ['Bebas Neue', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
    },
    {
        id: 11,
        name: "Reggae One",
        displayName: "Reggae One",
        fontFamily: ['Reggae One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Reggae+One&display=swap"
    },
    {
        id: 12,
        name: "Dancing Script",
        displayName: "Dancing Script",
        fontFamily: ['Dancing Script', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap"
    },
    {
        id: 13,
        name: "Lobster",
        displayName: "Lobster",
        fontFamily: ['Lobster', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Lobster&display=swap"
    },
    {
        id: 14,
        name: "Source Code Pro",
        displayName: "Source Code Pro",
        fontFamily: ['Source Code Pro', 'monospace'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap"
    },
    {
        id: 15,
        name: "Pacifico",
        displayName: "Pacifico",
        fontFamily: ['Pacifico', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
    },
    {
        id: 16,
        name: "Indie Flower",
        displayName: "Indie Flower",
        fontFamily: ['Indie Flower', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap"
    },
    {
        id: 17,
        name: "Shadows Into Light",
        displayName: "Shadows Into Light",
        fontFamily: ['Shadows Into Light', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap"
    },
    {
        id: 18,
        name: "Abril Fatface",
        displayName: "Abril Fatface",
        fontFamily: ['Abril Fatface', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Abril Fatface&display=swap"
    },
    {
        id: 19,
        name: "Amatic SC",
        displayName: "Amatic SC",
        fontFamily: ['Amatic SC', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap"
    },
    {
        id: 20,
        name: "Staatliches",
        displayName: "Staatliches",
        fontFamily: ['Staatliches', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Staatliches&display=swap"
    },
    {
        id: 21,
        name: "Fredoka One",
        displayName: "Fredoka One",
        fontFamily: ['Fredoka One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap"
    },
    {
        id: 22,
        name: "Caveat",
        displayName: "Caveat",
        fontFamily: ['Caveat', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Caveat&display=swap"
    },
    {
        id: 23,
        name: "Seymour One",
        displayName: "Seymour One",
        fontFamily: ['Seymour One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Seymour+One&display=swap"
    },
    {
        id: 24,
        name: "Permanent Marker",
        displayName: "Permanent Marker",
        fontFamily: ['Permanent Marker', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
    },
    {
        id: 25,
        name: "Bree Serif",
        displayName: "Bree Serif",
        fontFamily: ['Bree Serif', 'serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap"
    },
    {
        id: 26,
        name: "Righteous",
        displayName: "Righteous",
        fontFamily: ['Righteous', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Righteous&display=swap"
    },
    {
        id: 27,
        name: "Alfa Slab One",
        displayName: "Alfa Slab One",
        fontFamily: ['Alfa Slab One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap"
    },
    {
        id: 28,
        name: "Satisfy",
        displayName: "Satisfy",
        fontFamily: ['Satisfy', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Satisfy&display=swap"
    },
    {
        id: 29,
        name: "Barlow Semi Condensed",
        displayName: "Barlow Semi Condensed",
        fontFamily: ['Barlow Semi Condensed', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&display=swap"
    },
    {
        id: 30,
        name: "Vollkorn",
        displayName: "Vollkorn",
        fontFamily: ['Vollkorn', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Vollkorn&display=swap"
    },
    {
        id: 31,
        name: "Amiri",
        displayName: "Amiri",
        fontFamily: ['Amiri', 'serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Amiri&display=swap"
    },
    {
        id: 32,
        name: "Cinzel",
        displayName: "Cinzel",
        fontFamily: ['Cinzel', 'serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Cinzel&display=swap"
    },
    {
        id: 33,
        name: "Jura",
        displayName: "Jura",
        fontFamily: ['Jura', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Jura&display=swap"
    },
    {
        id: 34,
        name: "Courgette",
        displayName: "Courgette",
        fontFamily: ['Courgette', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Courgette&display=swap"
    },
    {
        id: 35,
        name: "ABeeZee",
        displayName: "ABeeZee",
        fontFamily: ['ABeeZee', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=ABeeZee&display=swap"
    },
    {
        id: 36,
        name: "Lobster Two",
        displayName: "Lobster Two",
        fontFamily: ['Lobster Two', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap"
    },
    {
        id: 37,
        name: "Francois One",
        displayName: "Francois One",
        fontFamily: ['Francois One', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Francois+One&display=swap"
    },
    {
        id: 38,
        name: "Secular One",
        displayName: "Secular One",
        fontFamily: ['Secular One', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Secular+One&display=swap"
    },
    {
        id: 39,
        name: "Russo One",
        displayName: "Russo One",
        fontFamily: ['Russo One', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Russo+One&display=swap"
    },
    {
        id: 40,
        name: "Philosopher",
        displayName: "Philosopher",
        fontFamily: ['Philosopher', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Philosopher&display=swap"
    },
    {
        id: 41,
        name: "Kalam",
        displayName: "Kalam",
        fontFamily: ['Kalam', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Kalam&display=swap"
    },
    {
        id: 42,
        name: "Patrick Hand",
        displayName: "Patrick Hand",
        fontFamily: ['Patrick Hand', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap"
    },
    {
        id: 43,
        name: "Kaushan Script",
        displayName: "Kaushan Script",
        fontFamily: ['Kaushan Script', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap"
    },
    {
        id: 44,
        name: "Big Shoulders Inline Display",
        displayName: "Big Shoulders Inline Display",
        fontFamily: ['Big Shoulders Inline Display', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Display&display=swap"
    },
    {
        id: 45,
        name: "Archivo Black",
        displayName: "Archivo Black",
        fontFamily: ['Archivo Black', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap"
    },
    {
        id: 46,
        name: "Great Vibes",
        displayName: "Great Vibes",
        fontFamily: ['Great Vibes', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap"
    },
    {
        id: 47,
        name: "Sacramento",
        displayName: "Sacramento",
        fontFamily: ['Sacramento', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Sacramento&display=swap"
    },
    {
        id: 48,
        name: "Concert One",
        displayName: "Concert One",
        fontFamily: ['Concert One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Concert+One&display=swap"
    },
    {
        id: 49,
        name: "Pathway Gothic One",
        displayName: "Pathway Gothic One",
        fontFamily: ['Pathway Gothic One', 'sans-serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap"
    },
    {
        id: 50,
        name: "Gloria Hallelujah",
        displayName: "Gloria Hallelujah",
        fontFamily: ['Gloria Hallelujah', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap"
    },
    {
        id: 51,
        name: "Passion One",
        displayName: "Passion One",
        fontFamily: ['Passion One', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Passion+One&display=swap"
    },
    {
        id: 52,
        name: "Yellowtail",
        displayName: "Yellowtail",
        fontFamily: ['Yellowtail', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Yellowtail&display=swap"
    },
    {
        id: 53,
        name: "Cookie",
        displayName: "Cookie",
        fontFamily: ['Cookie', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Cookie&display=swap"
    },
    {
        id: 54,
        name: "Risque",
        displayName: "Risque",
        fontFamily: ['Risque', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Risque&display=swap"
    },
    {
        id: 55,
        name: "Parisienne",
        displayName: "Parisienne",
        fontFamily: ['Parisienne', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Parisienne&display=swap"
    },
    {
        id: 56,
        name: "Ultra",
        displayName: "Ultra",
        fontFamily: ['Ultra', 'serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Ultra&display=swap"
    },
    {
        id: 57,
        name: "Luckiest Guy",
        displayName: "Luckiest Guy",
        fontFamily: ['Luckiest Guy', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap"
    },
    {
        id: 58,
        name: "Playfair Display SC",
        displayName: "Playfair Display SC",
        fontFamily: ['Playfair Display SC', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap"
    },
    {
        id: 59,
        name: "Special Elite",
        displayName: "Special Elite",
        fontFamily: ['Special Elite', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Special+Elite&display=swap"
    },

    {
        id: 60,
        name: "Merienda",
        displayName: "Merienda",
        fontFamily: ['Merienda', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Merienda&display=swap"
    },
    {
        id: 61,
        name: "Bangers",
        displayName: "Bangers",
        fontFamily: ['Bangers', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Bangers&display=swap"
    },
    {
        id: 62,
        name: "Imbue",
        displayName: "Imbue",
        fontFamily: ['Imbue', 'serif'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Imbue&display=swap"
    },
    {
        id: 63,
        name: "Bungee",
        displayName: "Bungee",
        fontFamily: ['Bungee', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Bungee&display=swap"
    },
    {
        id: 64,
        name: "Chewy",
        displayName: "Chewy",
        fontFamily: ['Chewy', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Chewy&display=swap"
    },
    {
        id: 65,
        name: "Rock Salt",
        displayName: "Rock Salt",
        fontFamily: ['Rock Salt', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap"
    },
    {
        id: 66,
        name: "Bungee Outline",
        displayName: "Bungee Outline",
        fontFamily: ['Bungee Outline', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap"
    },
    {
        id: 67,
        name: "Monoton",
        displayName: "Monoton",
        fontFamily: ['Monoton', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Monoton&display=swap"
    },
    {
        id: 68,
        name: "Allan",
        displayName: "Allan",
        fontFamily: ['Allan', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Allan&display=swap"
    },
    {
        id: 69,
        name: "Tangerine",
        displayName: "Tangerine",
        fontFamily: ['Tangerine', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Tangerine&display=swap"
    },
    {
        id: 70,
        name: "Playball",
        displayName: "Playball",
        fontFamily: ['Playball', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Playball&display=swap"
    },
    {
        id: 71,
        name: "Shrikhand",
        displayName: "Shrikhand",
        fontFamily: ['Shrikhand', 'cursive'],
        preConnect: "https://fonts.gstatic.com",
        hostPath: "https://fonts.googleapis.com/css2?family=Shrikhand&display=swap"
    },
    {
        id: 72,
        name: "Brock Script",
        displayName: "Brock Script",
        fontFamily: ['Brock Script', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/brock-script"
    },
    {
        id: 73,
        name: "CAC Champagne",
        displayName: "CAC Champagne",
        fontFamily: ['CAC Champagne', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/cac-champagne"
    },
    {
        id: 74,
        name: "Gabrielle",
        displayName: "Gabrielle",
        fontFamily: ['Gabrielle', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Gabrielle"
    },
    {
        id: 75,
        name: "Kingthings Petrock",
        displayName: "Kingthings Petrock",
        fontFamily: ['Kingthings Petrock', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Kingthings-Petrock"
    },
    {
        id: 76,
        name: "Marketing Script",
        displayName: "Marketing Script",
        fontFamily: ['Marketing Script', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Marketing-Script"
    },
    {
        id: 77,
        name: "Promocyja",
        displayName: "Promocyja",
        fontFamily: ['Promocyja', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Promocyja"
    },
    {
        id: 78,
        name: "Renaissance",
        displayName: "Renaissance",
        fontFamily: ['Renaissance', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Renaissance"
    },
    {
        id: 79,
        name: "Old Script",
        displayName: "Old Script",
        fontFamily: ['Old Script', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Old-Script"
    },
    {
        id: 80,
        name: "Vtks Beautiful Dreams",
        displayName: "Vtks Beautiful Dreams",
        fontFamily: ['Vtks Beautiful Dreams', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Vtks-Beautiful-Dreams"
    },
    {
        id: 81,
        name: "Exmouth",
        displayName: "Exmouth",
        fontFamily: ['Exmouth', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Exmouth"
    },
    {
        id: 82,
        name: "Loopi",
        displayName: "Loopi",
        fontFamily: ['Loopi', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Loopi"
    },
    {
        id: 83,
        name: "Lauren Script",
        displayName: "Lauren Script",
        fontFamily: ['Lauren Script', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Lauren-Script"
    },
    {
        id: 84,
        name: "Eutemia",
        displayName: "Eutemia",
        fontFamily: ['Eutemia', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Eutemia"
    },
    {
        id: 85,
        name: "Hanshand",
        displayName: "Hanshand",
        fontFamily: ['Hanshand', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Hanshand"
    },
    {
        id: 86,
        name: "Odstemplik",
        displayName: "Odstemplik",
        fontFamily: ['Odstemplik', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Odstemplik"
    },
    {
        id: 87,
        name: "Chancery Cursive",
        displayName: "Chancery Cursive",
        fontFamily: ['Chancery Cursive', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Chancery-Cursive"
    },
    {
        id: 88,
        name: "Juergen",
        displayName: "Juergen",
        fontFamily: ['Juergen', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Juergen"
    },
    {
        id: 89,
        name: "Gourdie Cursive",
        displayName: "Gourdie Cursive",
        fontFamily: ['Gourdie Cursive', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Gourdie-Cursive"
    },
    {
        id: 90,
        name: "Gourdie Handwriting",
        displayName: "Gourdie Handwriting",
        fontFamily: ['Gourdie Handwriting', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Gourdie-Handwriting"
    },
    {
        id: 91,
        name: "Cursive",
        displayName: "Cursive",
        fontFamily: ['Cursive', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Cursive"
    },
    {
        id: 92,
        name: "Ecolier",
        displayName: "Ecolier",
        fontFamily: ['Ecolier', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Ecolier"
    },
    {
        id: 93,
        name: "Cursif",
        displayName: "Cursif",
        fontFamily: ['Cursif', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Cursif"
    },
    {
        id: 94,
        name: "Rolina",
        displayName: "Rolina",
        fontFamily: ['Rolina', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Rolina"
    },
    {
        id: 95,
        name: "Fineliner Script",
        displayName: "Fineliner Script",
        fontFamily: ['Fineliner Script', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Fineliner-Script"
    },
    {
        id: 96,
        name: "Sue Ellen Francis",
        displayName: "Sue Ellen Francis",
        fontFamily: ['Sue Ellen Francis', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Sue-Ellen-Francis"
    },
    {
        id: 97,
        name: "Pennarelo",
        displayName: "Pennarelo",
        fontFamily: ['Pennarelo', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Pennarelo"
    },
    {
        id: 98,
        name: "Marker-SD",
        displayName: "Marker-SD",
        fontFamily: ['Marker-SD', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Marker-SD"
    },
    {
        id: 99,
        name: "Font On A Stick",
        displayName: "Font On A Stick",
        fontFamily: ['Font On A Stick', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Font-On-A-Stick"
    },
    {
        id: 100,
        name: "Chinela Brush",
        displayName: "Chinela Brush",
        fontFamily: ['Chinela Brush', 'cursive'],
        preConnect: "",
        hostPath: "http://fonts.cdnfonts.com/css/Chinela-Brush"
    },
]
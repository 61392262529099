import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Heading, HStack, Icon, IconButton, Input, Select, Spacer, Stack, Text, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaCopy, FaPlus, FaPlusSquare } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import WrapperBox from '../../components/wrapperBox/WrapperBox'
import { nanoid } from 'nanoid'
import firebase from 'firebase/app'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import copy from 'copy-to-clipboard'

export default function ApiCenterPage() {

    const { db } = GlobalDashBoardHandler()
    const { currentUser } = useAuth()
    const toast = useToast()
    const [pageData, setPageData] = useState({
        templatesData: [],
        eventsData: [],
        organisationsData: [],
        authorizationsData: [],
        loading: false,
        error: {},
        errorStatus: false,
    })
    const history = useHistory()
    const orgRef = useRef<HTMLSelectElement>(null)
    const eventRef = useRef<HTMLSelectElement>(null)
    const templateRef = useRef<HTMLSelectElement>(null)
    const apiKeyNameRef = useRef<HTMLInputElement>(null)
    const variant = useBreakpointValue({ base: "vertical", md: "horizontal" })
    const { isOpen, onOpen, onClose } = useDisclosure()

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    const fetchFirebaseDataForGivenCollection = ( collection:string ) => {
        try {
            return db
            .collection(collection)
            .where("userId", "==", currentUser.uid)
            .where("active", "==", true)
            .orderBy("updatedAt", "desc")
            .get()
        } catch (error) {
            setPageData({
                ...pageData,
                loading: false,
                error,
                errorStatus: true,
            })
            displayToastErrors(error.code?error.code:'Unable to fetch events', 
                            error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
    }

    const updateApiKeyData = async () => {
        const authorizationsData = await fetchFirebaseDataForGivenCollection('authorizations')
        let tempKeyData:object[] = []
        authorizationsData.forEach((doc:any) => {
            tempKeyData.push(doc.data())
        });
        
        setPageData({
            ...pageData,
            authorizationsData: tempKeyData as never[],
        })
    }

    const createNewApiKey = async () => {
        let docRef = db.collection("authorizations")
        const newApiKey = nanoid(50)
        docRef = docRef.doc(nanoid())
        const apiKeyObject = {
            userId: currentUser.uid,
            apiKey: newApiKey,
            keyName: apiKeyNameRef.current?.value,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            active: true
        }

        try {
            await docRef.set(apiKeyObject)
            await updateApiKeyData()
        } catch (error) {
            displayToastErrors(error.code?error.code:'Unable to fetch organisations', 
                                error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        }
    }

    const createDataForTable = (data:object[]) => {
        let returnObject = {
            keys: [
                "keyName",
                "apiKey"
            ],
            header: {
                keyName: "Key Name",
                apiKey: "API Key",
            },
            body: [] as object[]
        }
        
        data.map( (item:any) => {
            returnObject.body.push({
                keyName: <Text>{item.keyName}</Text>,
                apiKey: <HStack>
                    <Text wordBreak="break-all">{item.apiKey}</Text>
                    <Icon 
                        as={FaCopy} 
                        onClick={ (e) => {
                            copy(item.apiKey)
                            toast({
                                title: 'Copied',
                                status: 'success',
                                isClosable: true,
                                duration: 1000,
                                position: 'bottom-right',
                            })
                        }} 
                        cursor="copy" 
                        color="teal" 
                        ml={2} 
                    />
                </HStack>
            })
            return true
        })

        return returnObject
    }

    useEffect(() => {
        ( async () => {

            let tempDataHolder:Object[] = []
            let tempPromisHolder = await Promise.all(
                [
                    await fetchFirebaseDataForGivenCollection('templates'),
                    await fetchFirebaseDataForGivenCollection('events'),
                    await fetchFirebaseDataForGivenCollection('organisations'),
                    await fetchFirebaseDataForGivenCollection('authorizations')
                ]
            )

            tempPromisHolder.forEach((data) => {
                let subTempArr:Object[] = []
                data.forEach((doc:Object) => {
                    //@ts-ignore
                    subTempArr.push(doc.data())
                })
                tempDataHolder.push(subTempArr)
            })

            setPageData({
                ...pageData,
                templatesData: tempDataHolder[0] as never[],
                eventsData: tempDataHolder[1] as never[],
                organisationsData: tempDataHolder[2] as never[],
                authorizationsData: tempDataHolder[3] as never[],
            })
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box p={3}>
            <WrapperBox
                mb={5}
                placeholderText="API Details"
            >
                <Flex>
                    <Spacer/>
                    <IconButton
                        size="lg"
                        fontSize="lg"
                        colorScheme="teal"
                        onClick={onOpen}
                        borderRadius="50%"
                        mr="3"
                        my="3"
                        icon={<FaPlus />}
                        aria-label="Create new API Key"
                    />
                </Flex>
                {
                    (pageData.authorizationsData.length === 0)?
                    <Text textAlign="center">
                        You do not have any API key, Please create one
                    </Text>
                    :
                    <ResponsiveTable data={createDataForTable(pageData.authorizationsData)} />
                }
            </WrapperBox>
            <WrapperBox
                placeholderText="Choose event details to get API details"
                mb={5}
            >
                <Flex direction={['column',null,'row']} justifyContent="space-between" minH="100px" alignItems="center" >
                    <Box p={3}>
                        <Stack>
                            <Heading as="h5" size="sm">
                                Choose Organisation
                            </Heading>
                            {
                                (pageData.organisationsData.length === 0)?
                                <>
                                    <Text>No templates found</Text>
                                    <Button
                                        colorScheme="teal"
                                        rightIcon={<FaPlusSquare />}
                                        size="sm"
                                        onClick={() => {
                                            history.push('/dashboard/templates')
                                        }}
                                    >
                                        Create One
                                    </Button>
                                </>
                                :
                                <Select ref={orgRef}>
                                    {
                                        pageData.organisationsData.map( ( item:any ) => {
                                            return (
                                                <option value={item.organisationId} key={`"${item.organisationId}"`}> {item.organisationName} </option>
                                            )
                                        })
                                    }
                                </Select>
                            }
                        </Stack>
                    </Box>
                    {
                        (variant === 'horizontal')?
                        <Divider orientation="vertical" h={["0px", null, "100px", null]} />
                        :
                        <Divider orientation="horizontal" w={["100%", null, "0%", null]} />
                    }
                    <Box p={3}>
                        <Stack>
                            <Heading as="h5" size="sm">
                                Choose Event
                            </Heading>
                            {
                                (pageData.eventsData.length === 0)?
                                <>
                                    <Text>No event found</Text>
                                    <Button
                                        colorScheme="teal"
                                        rightIcon={<FaPlusSquare />}
                                        size="sm"
                                        onClick={() => {
                                            history.push('/dashboard/events')
                                        }}
                                    >
                                        Create One
                                    </Button>
                                </>
                                :
                                <Select ref={eventRef}>
                                    {
                                        pageData.eventsData.map( ( item:any ) => {
                                            return (
                                                <option value={item.eventId} key={`"${item.eventId}"`}> {item.eventName} </option>
                                            )
                                        })
                                    }
                                </Select>
                            }
                        </Stack>
                    </Box>
                    {
                        (variant === 'horizontal')?
                        <Divider orientation="vertical" h={["0px", null, "100px", null]} />
                        :
                        <Divider orientation="horizontal" w={["100%", null, "0%", null]} />
                    }
                    <Box p={3}>
                        <Stack>
                            <Heading as="h5" size="sm">
                                Choose Template
                            </Heading>
                            {
                                (pageData.templatesData.length === 0)?
                                <>
                                    <Text>No templates found</Text>
                                    <Button
                                        colorScheme="teal"
                                        rightIcon={<FaPlusSquare />}
                                        size="sm"
                                        onClick={() => {
                                            history.push('/dashboard/templates')
                                        }}
                                    >
                                        Create One
                                    </Button>
                                </>
                                :
                                <Select ref={templateRef}>
                                    {
                                        pageData.templatesData.map( ( item:any ) => {
                                            return (
                                                <option value={item.templateId} key={`"${item.templateId}"`}> {item.templateName} </option>
                                            )
                                        })
                                    }
                                </Select>
                            }
                        </Stack>
                    </Box>
                </Flex>
            </WrapperBox>
            <WrapperBox
                placeholderText="API Documantation"
            >
                <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Section 1 title
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Section 2 title
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </WrapperBox>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Create New API Key</DrawerHeader>

                        <DrawerBody>
                            <Input placeholder="Key Name" ref={apiKeyNameRef} />
                        </DrawerBody>

                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button 
                                colorScheme="teal"
                                onClick={()=>{
                                    createNewApiKey()
                                    onClose()
                                }}
                            >
                                Create
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Box>
    )
}

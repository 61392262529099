import { Box, Button, Divider, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import HeaderNavbar from '../../components/HeaderNavbar'
import LoginForm from '../../components/loginForm/LoginForm'

export default function Login() {
    return (
        <Flex direction="column">
            <Flex flexBasis="48px">
                <HeaderNavbar />
            </Flex>
            <Flex flexGrow={1} alignItems="center" flexBasis="calc(100vh - 48px)" alignSelf="center" alignContent="center">
                <VStack>
                    <Box boxShadow="md" p={3} rounded="md" borderTop={1} borderColor={'blackAlpha.400'} >
                        <Heading as="h2" size="xl" textAlign="center">
                            Log In
                        </Heading>
                        <LoginForm />
                        <Divider my={4} colorScheme="teal" />
                        <Text textAlign="center" >New user ? 
                            <Button colorScheme="teal" mt={-1} ml={2} variant="link">
                                <Link to="/signup">
                                    Sign Up
                                </Link>
                            </Button>
                        </Text>
                    </Box>
                </VStack>
            </Flex>
        </Flex>
    )
}

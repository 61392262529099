import { Avatar, AvatarBadge, Box, Button, Container, Divider, FormControl, Text, FormHelperText, FormLabel, Heading, HStack, Icon, IconButton, Input, InputGroup, InputLeftAddon, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, PinInput, PinInputField, Stack, useDisclosure, useToast, RadioGroup, Radio, Flex, Spacer } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaCalendar, FaCheckCircle, FaCloudUploadAlt, FaEdit, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhone, FaRegPlusSquare, FaTwitter, FaUser } from 'react-icons/fa'
import Counter from '../components/counter/Counter'
import PhoneInputWithDarkMode from '../components/phoneInputWithDarkMode/PhoneInputWithDarkMode'
import { useAuth } from '../contexts/AuthContext'
import { GlobalDashBoardHandler } from '../contexts/DashBoardContext'

export default function UserProfile() {
    const { currentUser, loadAdvanceCaptcha, linkPhoneWithUserSendOTP, linkPhoneWithUserVerifyOTP } = useAuth()
    const hiddenProfilePicRef = useRef<HTMLInputElement>(null)
    const [photoURL, setPhotoURL] = useState(currentUser.photoURL)
    const [showUpdateProfileButton, setShowUpdateProfileButton] = useState(false)
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [editPhoneVal, setEditPhoneVal] = useState<string>()
    const [editPhoneOTP, setEditPhoneOTP] = useState<string>()
    const [showOtpValHolder, setshowOtpValHolder] = useState(false)
    const [isResendOTPDisabled, setIsResendOTPDisabled] = useState(false)
    const [isVerifyOTPButtonLoading, setIsVerifyOTPButtonLoading] = useState(false)
    const [isSendOTPButtonLoading, setIsSendOTPButtonLoading] = useState(false)
    const [isUploadProfileButtonLoading, setIsUploadProfileButtonLoading] = useState(false)
    const { db, storage } = GlobalDashBoardHandler()

    const defaultUserInfo = {
        name: currentUser.displayName || '',
        email: currentUser.email || '',
        phone: currentUser.phoneNumber || '',
        gender: currentUser.gender || '',
        dob: currentUser.dob || '',
        linkedInId: currentUser.linkedInId || '',
        facebookId: currentUser.facebookId || '',
        twitterId: currentUser.twitterId || '',
        instaId: currentUser.instaId || ''
    }

    const defaultUserError = {
        name: '',
        email: '',
        phone: '',
        gender: '',
        dob: '',
        linkedInId: '',
        facebookId: '',
        twitterId: '',
        instaId: ''
    }

    const nameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)
    const dobRef = useRef<HTMLInputElement>(null)
    const linkedInIdRef = useRef<HTMLInputElement>(null)
    const facebookIdRef = useRef<HTMLInputElement>(null)
    const twitterIdRef = useRef<HTMLInputElement>(null)
    const instaIdRef = useRef<HTMLInputElement>(null)

    const [dataObject, setDataObject] = useState(defaultUserInfo)
    const [errorObject, setErrorObject] = useState(defaultUserError)

    let docRef = db.collection("users").doc(currentUser.uid)

    useEffect(() => {

        docRef.get().then(function(doc:any) {
            if (doc.exists) {
                const dataFromFirebase = doc.data()
                setDataObject({
                    ...dataObject,
                    ...dataFromFirebase
                })
            }
        }).catch(function(error:any) {
            displayToastErrors(error.code?error.code:'Something went wrong', 
                            error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function isFileImage(file:any) {
        return file && file['type'].split('/')[0] === 'image';
    }

    function handleEditProfileClick() {
        hiddenProfilePicRef.current?.click()
    }

    function handleProfilePicChange() {
        console.log(hiddenProfilePicRef);
        console.log(URL.createObjectURL(hiddenProfilePicRef?.current?.files![0]));
        
        if (isFileImage(hiddenProfilePicRef?.current?.files![0])) {
            setPhotoURL(URL.createObjectURL(hiddenProfilePicRef?.current?.files![0]))
            setShowUpdateProfileButton(true)
        } else {
            toast({
                title: "Not an image",
                description: "The file you selected is not an image file. Please use only images",
                status: "error",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
        }
    }

    function handleNameChange() {
        
        let nameVal = nameRef.current?.value
        console.log(nameVal);
        
        console.log(dataObject)
        setDataObject({
            ...dataObject,
            name: nameVal
        })
        console.log(dataObject)
        
        if(nameVal === ''){
            setErrorObject({
                ...errorObject,
                name: 'Name Can\'t be null'
            })
            return false
        }

        if(nameVal?.length! < 4){
            setErrorObject({
                ...errorObject,
                name: 'Name too short'
            })
            return false
        }
        setErrorObject({
            ...errorObject,
            name: ''
        })
    }

    function handleEmailChange() {
        let emailVal = emailRef.current?.value

        setDataObject({
            ...dataObject,
            email: emailVal
        })

        if(emailVal === ''){
            setErrorObject({
                ...errorObject,
                email: 'Email Can\'t be null'
            })
            return false
        }

        if(emailVal?.length! < 4){
            setErrorObject({
                ...errorObject,
                email: 'Email too short'
            })
            return false
        }

        if(!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailVal!))){
            setErrorObject({
                ...errorObject,
                email: 'Please enter a valid email address'
            })
            return false
        }

        setErrorObject({
            ...errorObject,
            email: ''
        })
    }

    async function handleSendOTPButton() {
        setIsSendOTPButtonLoading(true)
        await loadAdvanceCaptcha()
        const sendOTPResult = await linkPhoneWithUserSendOTP(editPhoneVal)
        setIsSendOTPButtonLoading(false)
        setIsResendOTPDisabled(true)
        if (sendOTPResult.statusCode === 200) {
            setshowOtpValHolder(true)
        } else {
            let error = sendOTPResult.data
            toast({
                title: error.code?error.code:"Something went wrong",
                description: error.message?error.message:"Sorry but we are unable to create account at the moment. Please try again after some time.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
        }
    }

    async function handleVerifyOTPButton() {
        setIsVerifyOTPButtonLoading(true)
        const verifyOTPResult = await linkPhoneWithUserVerifyOTP(editPhoneOTP)
        setIsVerifyOTPButtonLoading(false)
        if (verifyOTPResult.statusCode === 200) {
            toast({
                title: "Linked Successfully",
                description: "Congretulations ! your number "+editPhoneVal+" linked with your profile successfully",
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
            setDataObject({
                ...dataObject,
                phone: editPhoneVal
            })
            onClose()
        } else {
            let error = verifyOTPResult.data
            toast({
                title: error.code?error.code:"Something went wrong",
                description: error.message?error.message:"Sorry but we are unable to create account at the moment. Please try again after some time.",
                status: "error",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
        }
    }

    function handleDateChange() {

        let dobVal = dobRef.current?.value
        
        setDataObject({
            ...dataObject,
            dob: dobVal
        })

        if (new Date(dobVal!).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)) {
            setErrorObject({
                ...errorObject,
                dob: 'Nice Try! but we had trouble when we used to let fetus operate 😉' 
            })
            return false
        }

        if (new Date(dobVal!).setHours(0,0,0,0) < new Date('1900-01-01').setHours(0,0,0,0)) {
            setErrorObject({
                ...errorObject,
                dob: 'However we root for our elderly to use technology, But we don\'t see your name in the world record' 
            })
            return false
        }

        setErrorObject({
            ...errorObject,
            dob: '' 
        })
        
    }

    function handleLinkedInIdChange() {
        let linkedInIdVal = linkedInIdRef.current?.value

        setDataObject({
            ...dataObject,
            linkedInId: linkedInIdVal
        })

        setErrorObject({
            ...errorObject,
            linkedInId: '' 
        })
    }

    function handleFacebookIdChange() {
        let facebookIdVal = facebookIdRef.current?.value

        setDataObject({
            ...dataObject,
            facebookId: facebookIdVal
        })

        setErrorObject({
            ...errorObject,
            facebookId: '' 
        })
    }

    function handleTwitterIdChange() {
        let twitterIdVal = twitterIdRef.current?.value

        setDataObject({
            ...dataObject,
            twitterId: twitterIdVal
        })

        setErrorObject({
            ...errorObject,
            twitterId: '' 
        })
    }

    function handleInstaIdChange() {
        let instaIdVal = instaIdRef.current?.value

        setDataObject({
            ...dataObject,
            instaId: instaIdVal
        })

        setErrorObject({
            ...errorObject,
            instaId: '' 
        })
    }

    function isAllValuesInObjectEmpty(object:object) {
        return !Object.values(object).some(x => (x !== null && x !== ''));
    }

    function returnFirstNonNullValue(object:object): any {
        return Object.values(object).map((val) => {
            if (val !== null && val !== '' ) {
                return val
            } else {
                return ''
            }
        })
    }

    function displayToastErrors(title:string, description:string) {
        toast({
            title: title,
            description: description,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        })
    }

    async function handleSubmit() {
        setIsUploadProfileButtonLoading(true)
        
        if(isAllValuesInObjectEmpty(errorObject)){
            if ( photoURL !== currentUser.photoURL ) {

                const picName = hiddenProfilePicRef?.current?.files![0].name
                const imageRef = storage.ref().child('users').child(currentUser.uid).child('images/profile').child(picName)
                let downloadURL;

                try {
                    let storageResult = await imageRef.put(hiddenProfilePicRef?.current?.files![0])
                    downloadURL = await storageResult.ref.getDownloadURL()
                } catch (error) {
                    displayToastErrors(error.code?error.code:'Not Updated', 
                        error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
                }

                currentUser.updateProfile({
                    photoURL: downloadURL
                }).catch(function(error:any) {
                    displayToastErrors(error.code?error.code:'Not Updated', 
                        error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
                })
            }

            if ( currentUser.displayName !== dataObject.name ) {

                currentUser.updateProfile({
                    displayName: dataObject.name
                }).catch(function(error:any) {
                    displayToastErrors(error.code?error.code:'Not Updated', 
                        error.message?error.message:'It\'s us not you, While our cats are firefighting, You sit back relex and try after sometime')
                })
            }

            await docRef.set(dataObject, { merge: true })
            
            setIsUploadProfileButtonLoading(false)

            toast({
                title: "Update Successful",
                description: "Congretulations! Your profle update is successful",
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
            
        } else {
            toast({
                title: "Please correct following errors",
                description: returnFirstNonNullValue(errorObject),
                status: "error",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
            })
        }
    }

    function onErrorStateChange() {
        setShowUpdateProfileButton(isAllValuesInObjectEmpty(errorObject))
    }

    return (
        <Container maxW="xl" centerContent>
            <Box padding="4" m="2" my="8" boxShadow="md" borderWidth={1} borderRadius={10} wordBreak="break-word" w="100%" >
                <Container centerContent>
                    <Heading p={3} pb={6} textAlign="center">Profile Page</Heading>
                    <Avatar size="2xl" name={currentUser.displayName} src={photoURL} >
                        <AvatarBadge boxSize="0.8em" onClick={handleEditProfileClick} bg="gray.500" borderColor="gray.500" > 
                            <Icon as={FaEdit} w={6} h={6} color="white" />
                        </AvatarBadge>
                    </Avatar>
                    <Input type="file" id="hiddenProfilePic" display="none" ref={hiddenProfilePicRef} onChange={handleProfilePicChange} />

                    <Divider m={5} />

                    <FormControl onChange={onErrorStateChange}>
                        <Stack spacing={1}>
                            <FormLabel pt={5}>Full Name</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaUser />} />
                                <Input 
                                    type="text" 
                                    borderLeftRadius="0" 
                                    placeholder="Full Name" 
                                    onChange={() => handleNameChange()} 
                                    ref={nameRef}
                                    value={dataObject.name}
                                    textTransform="capitalize"
                                />
                            </InputGroup >
                            {errorObject?.name && <FormHelperText color="red.500">{ errorObject?.name }</FormHelperText>}

                            <FormLabel pt={5}>Email</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaEnvelope />} />
                                <Input 
                                    type="email" 
                                    borderLeftRadius="0" 
                                    placeholder="Email Address" 
                                    onChange={() => handleEmailChange()} 
                                    ref={emailRef}
                                    value={dataObject.email}
                                    isDisabled={true}
                                />
                            </InputGroup >
                            {errorObject?.email && <FormHelperText color="red.500">{ errorObject?.email }</FormHelperText>}

                            <FormLabel pt={5}>Mobile Number</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaPhone />} />
                                <Input 
                                    type="phone" 
                                    borderLeftRadius="0" 
                                    placeholder="Mobile Number" 
                                    onChange={() => handleEmailChange()} 
                                    ref={phoneRef}
                                    value={dataObject.phone}
                                    isDisabled={true}
                                />
                                <InputRightAddon children=
                                    {
                                        dataObject.phone?
                                            <Icon as={FaCheckCircle} color="green.500" w={5} h={5} />
                                        :
                                            <IconButton
                                                variant="ghost"
                                                color="current"
                                                aria-label={`Edit phone number`}
                                                icon={< FaRegPlusSquare />}
                                                m={-3}
                                                onClick={onOpen}
                                                id="send-otp-button" 
                                            />
                                    } 
                                />
                            </InputGroup >
                            {errorObject?.phone && <FormHelperText color="red.500">{ errorObject?.phone }</FormHelperText>}
                            
                            <FormLabel pt={5}> Gender </FormLabel>
                            <RadioGroup 
                                onChange={(val) => {
                                    setDataObject(
                                        {
                                            ...dataObject,
                                            gender: val
                                        }
                                    )
                                }} 
                                value={dataObject.gender}
                            >
                                <Flex direction={["column", "row", null, null]} >
                                    <Radio m={3} ml={0} value="Male">Male </Radio>
                                    <Spacer />
                                    <Radio m={3} ml={0} value="Female">Female </Radio>
                                    <Spacer />
                                    <Radio m={3} ml={0} value="Other">Other </Radio>
                                </Flex>
                            </RadioGroup>

                            <FormLabel pt={5}>Date of Birth</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaCalendar />} />
                                <Input 
                                    type="date" 
                                    borderLeftRadius="0" 
                                    placeholder="Full Name" 
                                    onChange={() => handleDateChange()} 
                                    ref={dobRef}
                                    value={dataObject.dob}
                                    min="1900-01-01"
                                    max={new Date().toISOString().slice(0,10)}
                                />
                            </InputGroup >
                            {errorObject?.dob && <FormHelperText color="red.500">{ errorObject?.dob }</FormHelperText>}

                            <FormLabel pt={5}>Linked In ID</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaLinkedinIn />} />
                                <Input 
                                    type="url" 
                                    borderLeftRadius="0" 
                                    placeholder="Linked In ID" 
                                    onChange={() => handleLinkedInIdChange()} 
                                    ref={linkedInIdRef}
                                    value={dataObject.linkedInId}
                                />
                            </InputGroup >
                            {errorObject?.linkedInId && <FormHelperText color="red.500">{ errorObject?.linkedInId }</FormHelperText>}

                            <FormLabel pt={5}>Facebook Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaFacebookF />} />
                                <Input 
                                    type="url" 
                                    borderLeftRadius="0" 
                                    placeholder="Facebook Handle" 
                                    onChange={() => handleFacebookIdChange()} 
                                    ref={facebookIdRef}
                                    value={dataObject.facebookId}
                                />
                            </InputGroup >
                            {errorObject?.facebookId && <FormHelperText color="red.500">{ errorObject?.facebookId }</FormHelperText>}

                            <FormLabel pt={5}>Twitter Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaTwitter />} />
                                <Input 
                                    type="url" 
                                    borderLeftRadius="0" 
                                    placeholder="Twitter Handle" 
                                    onChange={() => handleTwitterIdChange()} 
                                    ref={twitterIdRef}
                                    value={dataObject.twitterId}
                                />
                            </InputGroup >
                            {errorObject?.twitterId && <FormHelperText color="red.500">{ errorObject?.twitterId }</FormHelperText>}

                            <FormLabel pt={5}>Insta Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaInstagram />} />
                                <Input 
                                    type="url" 
                                    borderLeftRadius="0" 
                                    placeholder="Insta Handle" 
                                    onChange={() => handleInstaIdChange()} 
                                    ref={instaIdRef}
                                    value={dataObject.instaId}
                                />
                            </InputGroup >
                            {errorObject?.instaId && <FormHelperText color="red.500">{ errorObject?.instaId }</FormHelperText>}

                        </Stack>
                    </FormControl>

                    <Modal 
                        isOpen={isOpen} 
                        onClose={onClose}
                    >
                        <ModalOverlay />
                        <ModalContent>
                        <ModalHeader> Enter Mobile Number </ModalHeader>
                        <ModalCloseButton />

                        <ModalBody>
                            <PhoneInputWithDarkMode 
                                country={'in'}
                                placeholder="Enter mobile number"
                                value={editPhoneVal}
                                onChange={( phone:any ) => setEditPhoneVal( '+'+phone )}
                            />
                            {
                                showOtpValHolder && 
                                <Box mt={5} >
                                    <Text > Enter OTP</Text>
                                    <HStack mt={2}>
                                        <PinInput value={editPhoneOTP} onChange={(codeOTP) => setEditPhoneOTP(codeOTP) }>
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                </Box>
                            }
                        </ModalBody>

                        <ModalFooter>
                            <Button 
                                colorScheme="blue" 
                                mr={3} 
                                onClick={handleSendOTPButton} 
                                isDisabled={isResendOTPDisabled}
                                isLoading={isSendOTPButtonLoading}
                                loadingText="Sending"
                            >
                                { showOtpValHolder? (
                                        <>
                                            { isResendOTPDisabled? (
                                                    <>
                                                        <Text mr={1}>Resend in </Text>
                                                        <Counter 
                                                            ts={30} 
                                                            ss={1} 
                                                            callBackOnTimerEnd={() => setIsResendOTPDisabled(false) }
                                                        />
                                                    </>
                                                ) : <Text mr={1}>Resend OTP </Text>
                                            }
                                        </>
                                    ) : "Send OTP" }
                            </Button>
                            {
                                showOtpValHolder &&
                                <Button 
                                    colorScheme="blue" 
                                    mr={3} 
                                    onClick={handleVerifyOTPButton} 
                                    isLoading={isVerifyOTPButtonLoading}
                                    loadingText="Verifing"
                                >
                                    Verify OTP
                                </Button>
                            }
                        </ModalFooter>
                        </ModalContent>
                    </Modal>
                    
                    <Button 
                        variant="solid" 
                        colorScheme="blue"
                        m={5}
                        isDisabled={!showUpdateProfileButton}
                        rightIcon={ <FaCloudUploadAlt /> }
                        type="submit"
                        onClick={ () => handleSubmit() }
                        isLoading={isUploadProfileButtonLoading}
                        loadingText="Updating"
                    >
                        Update Profile
                    </Button>
                </Container>
            </Box>
        </Container>
    )
}

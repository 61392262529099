import { FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, InputLeftAddon, InputGroup, InputRightElement, Button, Divider, Text, useToast } from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaEnvelope, FaEye, FaEyeSlash, FaGoogle, FaUserLock } from 'react-icons/fa'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'

export default function LoginForm() {

    const { loginByEmail, loginByGoogle } = useAuth()
    const toast = useToast()
    const history = useHistory()
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [isEmailError, setIsEmailError] = useState(false)

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        confirmPassword: yup.string().min(8).required(),
    });
      
    type SignupFormInputs = {
        email: string;
        password: string;
        confirmPassword: string;
    };

    const { errors } = useForm<SignupFormInputs>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const [isGoogleButtonLoading, setIsGoogleButtonLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const checkEmailError = function () {
        if(!emailRef?.current?.value) {
            setIsEmailError(true)
            toast({
                title: "Email Error.",
                description: "Sorry, Email can not be null.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsButtonLoading(false)
            return false
        } else {
            setIsEmailError(false)
        }
    }

    async function handleLoginByEmail(e:any) {
        e.preventDefault()
        setIsButtonLoading(true)

        checkEmailError()

        try {
            await loginByEmail(emailRef?.current?.value, passwordRef?.current?.value)
            toast({
                title: "👏👏👏👏👏",
                description: "You have succesfully loged in",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsButtonLoading(false)
            history.push("/dashboard")
        } catch (error) {
            toast({
                title: error.code?error.code:"Something went wrong",
                description: error.message?error.message:"Sorry but we are unable to create account at the moment. Please try again after some time.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsButtonLoading(false)
        }
    }

    async function handleLoginByGoogle() {
        setIsGoogleButtonLoading(true)
        try {
            await loginByGoogle()
            toast({
                title: "👏👏👏👏👏",
                description: "You have succesfully loged in",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsGoogleButtonLoading(false)
            history.push("/dashboard")
        } catch (error) {
            toast({
                title: error.code?error.code:"Something went wrong",
                description: error.message?error.message:"Sorry but we are unable to create account at the moment. Please try again after some time.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right",
            })
            setIsGoogleButtonLoading(false)
        }
    }

    return (
        <form >
            <FormControl
                isInvalid={!!errors?.email}
                errortext={errors?.email?.message}
                p='4'
                isRequired
            >
                <FormLabel>Email</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaEnvelope color="gray.300" />} />
                    {/* <Input type='email' name='email' placeholder='Email' ref={emailRef} /> */}
                    <Input type="email" name='email' isRequired={true} onBlur={checkEmailError} isInvalid={isEmailError} borderLeftRadius="3" placeholder="Email" ref={emailRef} />
                </InputGroup>
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                <FormHelperText>
                    We are obviously giving this straight to Facebook.
                </FormHelperText>
            </FormControl>
            <FormControl
                isInvalid={!!errors?.password?.message}
                errortext={errors?.password?.message}
                px='4'
                pb='4'
                isRequired
            >
                <FormLabel>Password</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaUserLock color="gray.300" />} />
                    <Input ref={passwordRef} type={showPassword ? "text" : "password"} placeholder='Password' name='password' />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" mr={-5} variant="ghost" onClick={()=>setShowPassword(!showPassword)}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                <FormHelperText>
                    Keep it strong.
                </FormHelperText>
            </FormControl>
            <Button
                onClick={ (e) => {
                    handleLoginByEmail(e)
                }}
                p='4'
                mx='4'
                mt='2'
                w='90%'
                colorScheme='teal'
                variant='solid'
                disabled={!!errors.email || !!errors.password}
                isLoading={isButtonLoading}
                loadingText="Logging in..."
            >
                Log In
            </Button>
            <Divider my={4} colorScheme="teal" />
            <Text textAlign="center" >Or Login using</Text>
            <Button 
                colorScheme="red" 
                variant="solid" 
                isLoading={isGoogleButtonLoading}
                loadingText="Wait while we log you in"
                onClick={handleLoginByGoogle} 
                w="90%"
                mt={4}
                mx={4}
            >
                <FaGoogle />
            </Button>
        </form>
    )
}

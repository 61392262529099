import { useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneInputWithDarkMode.css'

export default function PhoneInputWithDarkMode( props:any ) {
    const phoneInputWrapperClassNameForHack = useColorModeValue("light", "dark")

    return (
        <div className={phoneInputWrapperClassNameForHack}>
            <PhoneInput
                inputProps={{
                    "style": {
                        "background": "inherit",
                        "width": "100%",
                        "height": "2.5rem",
                        "borderRadius": "0.375rem",
                        "borderColor": "inherit"
                    }
                }}
                { ...props }
            >
            </PhoneInput>
        </div>
    )
}

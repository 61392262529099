import { Divider, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import { menuData } from '../../dataObjects/SideBarObject'
import "./Sidebar.css"

export default function Sidebar() {
    const { deviceType, sidebarState } = GlobalDashBoardHandler()
    const menuDataList = menuData.data

    const isSidebarCollapsed = () => {
        if (deviceType === "portrait" ) {
            return ""
        }
        if ( sidebarState === 2 ) {
            return ""
        } else {
            return "short-sidebar-scale-down"
        }
    }

    const SidebarItem = ( props:any ) => {
        const { menuObj, ...propsToPass } = props
        return (
            <>
                <Link to={menuObj.link}>
                    <Flex direction="row" className={"list-hover-slide"} {...propsToPass} >
                        <Flex alignItems="center" m={5}>
                            <Icon as={menuObj.icon} w={30} h={30} />
                        </Flex>
                        <Flex alignItems="center">
                            <Text fontSize="20px" textTransform="capitalize" >
                                {menuObj.name}
                            </Text>
                        </Flex>
                    </Flex>
                </Link>
                <Divider />
            </>
        )
    }
    
    return (
        <Flex direction="column" h="100%" className={isSidebarCollapsed()} >
            {
                menuDataList.map( (menuDataObject) => {
                    return (
                        <SidebarItem key={menuDataObject.id} menuObj={menuDataObject} />
                    )
                })
            }
        </Flex>
    )
}

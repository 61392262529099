import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import HeaderNavbar from '../../components/HeaderNavbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { GlobalDashBoardHandler } from '../../contexts/DashBoardContext'
import ApiCenterPage from '../../subPages/apiCenterPage/ApiCenterPage'
import CertificatesPage from '../../subPages/certificatesPage/CertificatesPage'
import DashBoardHomeSubPage from '../../subPages/dashBoardHomePage/DashBoardHomeSubPage'
import EventsPage from '../../subPages/eventsPage/EventsPage'
import OrganisationsPage from '../../subPages/organisationsPage/OrganisationsPage'
import TemplatesPage from '../../subPages/templatesPage/TemplatesPage'
import UserProfile from '../../subPages/UserProfile'
import "./OuterLayout.css"

export default function OuterLayout() {
    const { headerHeight, footerHeight, calculateSidebarWidth } = GlobalDashBoardHandler()
    const footerColour = useColorModeValue("white", "#1a202c")
    const match = useRouteMatch()

    return (
        <Flex
            direction="column"
            h="100vh"
        >
            <Flex flexBasis={`${headerHeight}px`} borderBottomWidth={1}>
                <HeaderNavbar />
            </Flex>
            <Flex direction="row" flexGrow={1} h={`calc(100vh - ${headerHeight+footerHeight}px)`} >
                <Box flexBasis={`${calculateSidebarWidth()}px`} flexGrow={0} flexShrink={0} overflow="auto" overflowX="hidden" transition="flex-basis 0.4s" h={`calc(100vh - ${headerHeight+footerHeight}px)`} boxShadow="md" borderRightWidth={1}>
                    <Sidebar />
                </Box>
                <Box flexGrow={1} overflow="auto" transition="flex-basis 0.4s" h={`calc(100vh - ${headerHeight+footerHeight}px)`} >
                    <Switch>
                        <Route path={`${match.path}/profile`} component={UserProfile} />
                        <Route path={`${match.path}/templates/:templateId`} component={TemplatesPage} />
                        <Route path={`${match.path}/templates`} component={TemplatesPage} />
                        <Route path={`${match.path}/organisations`} component={OrganisationsPage} />
                        <Route path={`${match.path}/events`} component={EventsPage} />
                        <Route path={`${match.path}/certificates`} component={CertificatesPage} />
                        <Route path={`${match.path}/apicenter`} component={ApiCenterPage} />
                        {/* <Route path={`${match.path}/events`} component={EventsSubPage} />
                        <Route path={`${match.path}/certificates`} component={CertificatesSubPage} />
                        <Route path={`${match.path}/builder`} component={BuilderSubPage} />
                        <Route path={`${match.path}/verifyemail`} component={EmailVerificationModel} /> */}
                        <Route path={`${match.path}/`} component={DashBoardHomeSubPage} />
                    </Switch>
                </Box>
            </Flex>
            <Box flexBasis={`${footerHeight}px`} bg={footerColour} borderTopWidth={1}>
                <Footer />
            </Box>
        </Flex>
    )
}
